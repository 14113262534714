@import '../../variables';

.card-list {
  @include responsive-grid(2, 3, 4, 5);
  margin-top: 1rem;
  grid-gap: 1rem;

  &--list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  &--companies {
    @media screen and (min-width: $md-min-width) and (max-width: $lg-min-width) {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }
}
