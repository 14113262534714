@import 'company-card';
@import 'card-list';
@import 'view-controller';

.products-list {
  .products {
    @media screen and (min-width: 768px) {
      .filters-mobile {
        display: none;
      }
    }
  }
}
