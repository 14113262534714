.marketplace-footer {
  padding: 60px 0;
  background: #004d40;
  color: $white;

  a {
    font-weight: 700;
    text-decoration: none;
    color: $white;
  }

  h4 {

    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #69f0ae;
  }

  .ant-btn-primary__outlined {
    @include center-flex();

    width: 100%;
    margin-top: 12px;
    border-color: $white !important;
    border-width: 2px;
    gap: 10px;
    text-transform: uppercase;
    letter-spacing: 0.75px;
    color: $white !important;

    &:hover {
      border-color: $secondary !important;
      color: $secondary !important;
    }
  }

  svg {
    width: 24px;
    height: 24px;
  }

  &__logo {
    @media screen and (min-width: 1024px) {
      max-width: 200px;
      width: 100%;
    }

    @media screen and (max-width: 720px) {
      text-align: right;
    }
  }

  &__linkedin-desktop {
    a {
      display: grid;
      grid-template-columns: 32px 1fr;
      gap: 12px;

      svg {
        max-width: 32px;
        width: 100%;
      }
    }

  }

  &__policies {
    font-size: 14px;

    @media screen and (min-width: 720px) {
      text-align: right !important;
    }
  }

  &__registry {
    font-size: 12px;
    line-height: 16px;

    @media screen and (min-width: 720px) {
      text-align: left !important;
    }
  }
}
