.product-card {
  max-width: 100%;
  width: 100%;
  cursor: pointer;

  &__image {
    position: relative;

    &__badges {
      display: flex;
      gap: 8px;
      align-items: center;
      position: absolute;
      left: -12px;
      top: -12px;

      &__new-arrival,
      &__discount {
        padding: 4px 16px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 10px;
        border-radius: 24px;
      }
      &__new-arrival {
        background-color: #b4f7d6;
        color: $primary;
      }
      &__discount {
        background-color: $primary;
        color: $white;
      }
    }

    img {
      max-width: 100%;
      width: 100%;
    }

    &__title {
      display: none;
    }
  }

  &__data {
    &__supplier {
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        font-size: 12px;
        font-weight: 400;
        text-decoration: none;
        color: $primary;
      }

      &__icons {
        &__list,
        &__list__item {
          list-style: none;
          margin: 0;
          padding: 0;
        }

        &__list {
          display: flex;
          gap: 8px;

          &__item {
            img {
              max-width: 16px;
              max-height: 16px;
            }
          }
        }
      }
    }

    &__title {
      line-height: 0.8;

      a {
        font-size: 14px;
        font-weight: 600;
        text-decoration: none;
      }
    }

    &__divider {
      margin: 12px 0;
    }
  }

  &__rate-wrapper {
    margin: 0px 0px 8px 0px;
    display: flex;
    align-items: center;
    gap: 4px;

    &__rating {
      .ant-rate-star {
        margin: 0px !important;

        svg {
          height: 18px;
        }
      }
    }

    &__review-count {
      font-size: 12px;
      color: #aeaeaa;
    }
  }

  &__prices {
    &__price {
      margin-bottom: 8px;

      &__discount-value {
        margin-top: 4px;
        display: flex;
        align-items: center;
        gap: 8px;
        letter-spacing: 0.5px;

        span {
          font-size: 12px;
          text-decoration: line-through;
          color: #aeaeaa;
        }

        &__discount {
          max-width: fit-content;
          border: 1px solid $primary;
          border-radius: 1px;
          padding: 0px 5px;
          font-size: 12px;
          color: $primary;
        }
      }

      &__value {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 4px 0;
        font-size: 16px;
        font-weight: 700;
      }

      &__min-order {
        margin: 4px 0px 20px 0px;
        font-size: 12px;
        color: #aeaeaa;
      }

      // FIXME: Remove this
      // &--unauthorized {
      //   filter: blur(3px);
      // }
      &--unavailable {
        & > span {
          font-size: 16px;
          font-weight: 700;
          margin: 0px 0px 8px 0px;
        }

        button {
          margin: 8px 0px 0px 0px;
          max-height: 32px;
          width: 100%;
        }

        &--out-of-stock {
          & > span {
            color: #aeaeaa;
          }
        }

        &--on-demand {
          width: 100%;
          & > span {
            color: $primary;
          }
        }
      }
    }
  }

  &__footer {
    display: none;
    &__ttw-code {
      font-size: 12px;
      color: #aeaeaa;
    }
  }

  .ant-rate {
    display: flex;
    justify-content: space-around;
  }

  @media screen and (max-width: $sm-max-width) {
    padding: 8px !important;

    &__data {
      &__title {
        a {
          font-size: 14px;
        }
      }
    }

    &__prices {
      &__price {
        &--unavailable {
          text-align: center;
          & > span {
            font-size: 14px;
          }
        }

        &__value {
          font-size: 14px;

          &__discount {
            display: none;
          }
        }
      }
    }

    .ant-rate {
      svg {
        max-width: 14px;
      }
    }

    .tags {
      display: none;
    }
  }

  &--catalogue {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between;

    .product-card {
      &__image {
        img {
          max-width: 100% !important;
          max-height: 200px !important;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__data {
        &__title {
          height: 52px;
          margin: none !important;
        }

        .product-card__prices {
          display: none;
        }
      }

      &__prices {
        &__price {
          min-height: 72px;
        }
      }
    }

    // & > .product-card__prices {
    //   display: none;
    // }

    .tags {
      max-width: 100%;
    }

    @media screen and (min-width: $md-min-width) and (max-width: $lg-min-width) {
      padding: 12px !important;
      .product-card {
        &__rate-wrapper {
          .ant-rate {
            font-size: 18px !important;
          }
        }
      }
    }

    @media screen and (max-width: $lg-min-width) {
      &.product-card--list {
        .product-card {
          &__image {
            img {
              max-width: 120px !important;
              max-height: 120px !important;
              margin-right: 8px;
            }

            &__title {
              display: inline !important;
              .product-card__data {
                &__supplier {
                  display: flex !important;

                  &__icons {
                    display: block !important;

                    img {
                      max-width: 16px !important;
                      max-height: 16px !important;
                    }
                  }
                }

                &__divider {
                  display: block !important;
                }
              }
              .product-card__prices {
                display: block !important;

                &__price {
                  margin: none !important;
                  min-height: auto;

                  &__min-order {
                    display: none;
                  }
                }
              }
            }
          }

          &__data {
            display: none !important;
          }

          &__rate-wrapper {
            display: none !important;
          }

          .choose-options {
            display: none !important;
          }
        }
      }
    }

    @media screen and (min-width: $md-min-width) and (max-width: $lg-min-width) {
      &.product-card--list {
        .product-card {
          &__image {
            display: flex;
            max-width: 100%;
            margin-right: 0 !important;
            gap: 16px;
            img {
              max-width: 124px !important;
              max-height: 124px !important;
              // margin-right: 60px !important;
            }

            &__title {
              display: block !important;
              width: 100%;
              .product-card__data {
                &__supplier {
                  display: flex !important;

                  &__icons {
                    display: block !important;

                    img {
                      max-width: 16px !important;
                      max-height: 16px !important;
                    }
                  }
                }

                &__divider {
                  display: block !important;
                }
              }
              .product-card__prices {
                display: block !important;
                text-align: right !important;

                &__price {
                  margin: none !important;
                  min-height: auto;
                  display: flex !important;
                  justify-content: space-between !important;
                  align-items: baseline;

                  button {
                    width: 100% !important;
                  }

                  .product-card__rate-wrapper {
                    display: flex !important;
                  }

                  &__value {
                    display: block !important;
                  }

                  &__discount-value {
                    justify-content: flex-end !important;
                  }

                  &__min-order {
                    display: none;
                  }
                }
              }
            }
          }
          &__footer {
            display: none !important;
          }
        }
      }
    }
  }

  &--home {
    .product-card {
      &__data {
        &__title {
          height: 52px;
          margin: none !important;
        }
        .product-card__prices {
          display: none;
        }
      }
      &__prices {
        &__price {
          min-height: 72px;
        }
      }
    }
  }

  &--ss {
    @import '../../marketplace/product-gallery';

    .product-gallery {
      height: fit-content;
      overflow: visible;
    }

    .product-card {
      overflow-x: hidden;
      &__data {
        &__title {
          height: 52px;
          margin: none !important;
        }
        .product-card__prices {
          display: none;
        }

        .attr-options {
          width: 100%;
          row-gap: 8px;
        }
      }

      &__image {
        img {
          max-height: 196px;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &__footer {
        display: block;
      }
    }

    .tags {
      display: none;
      overflow: hidden;
    }

    &__modal {
      width: 70% !important;
      margin: auto !important;

      &__card {
        .expanded-details {
          max-width: 100%;
          width: 100%;
          margin: 2rem 0 0 0;
        }
      }
      .ant-modal-content {
        .ant-modal-header {
          display: none;
        }

        .ant-modal-body {
          height: 80vh;
          overflow-y: scroll;
        }

        .ant-modal-close {
          top: -44px;
          padding: 4px;
          background-color: $white;

          &-x {
            width: fit-content;
            height: fit-content;
            line-height: normal;
          }

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }

      &__close {
        margin-top: -40px;
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        font-weight: 700;
        color: #004d40;
        text-transform: uppercase;
        cursor: pointer;

        svg {
          margin-top: -2px;
          margin-right: 24px;
        }
      }

      .product-card {
        box-shadow: none;
        border: none;
      }
    }

    @media screen and (max-width: $lg-min-width) {
      &.product-card {
        .product-card {
          &__data {
            .product-card__prices {
              display: none;
            }
          }
        }
      }

      &__modal {
        max-width: 100% !important;
        width: 100% !important;
        height: 100vh !important;
        top: 0;

        &__close {
          display: block !important;
        }

        .ant-modal-body {
          padding: 60px 20px 0px !important;
          height: 100% !important;
        }

        .product-card {
          &__image {
            width: 100%;
            flex-wrap: wrap;

            .product-card__footer {
              display: block !important;
              width: fit-content !important;
              margin-left: 0 !important;
            }

            &__title {
              width: 100%;
            }

            .product-gallery {
              max-width: 100%;
              width: 100%;
              display: flex !important;
              justify-content: space-between;

              img {
                max-width: 162px !important;
                height: 162px !important;
                width: 100%;
              }

              .ant-carousel {
                min-width: 162px !important;
                width: 102px !important;
              }

              .slick-dots {
                display: block !important;
              }
            }
          }
        }
      }
    }

    @media screen and (min-width: $md-min-width) and (max-width: $lg-min-width) {
      &.product-card {
        .product-card {
          &__footer {
            display: block;
          }
        }

        .tags {
          display: none;
        }
      }

      &__modal {
        .product-card {
          &__image {
            .product-card__footer {
              display: none !important;
            }
          }
        }
      }
    }

    @media screen and (max-width: $md-min-width) {
      &.product-card--list {
        .product-card {
          &__data {
            max-width: 100%;
            width: 100%;

            .attr-options {
              width: 100%;
            }
          }

          &__image {
            width: 100%;

            &__title {
              width: 100%;
            }

            .product-gallery {
              max-width: 100%;

              img {
                max-width: 92px;
                height: 92px;
                width: 100%;
              }

              .ant-carousel {
                min-width: 92px;
                width: 102px;
              }

              .slick-dots {
                display: none !important;
              }
            }
          }
        }
      }
    }
  }

  &--list {
    display: flex;
    flex-wrap: wrap;

    .product-card {
      &__image {
        &__badges {
          z-index: 10000000000;
        }

        max-width: 165px;
        margin-right: 24px;

        .ant-carousel {
          min-width: 165px;
          padding: 0px !important;
        }

        .slick-dots {
          z-index: 10000000000;
          bottom: -30px;
          align-items: center;
          justify-content: center;
          gap: 8px;

          li {
            border-radius: 50%;
            width: 12px !important;
            height: 12px !important;

            button {
              display: block !important;
              border: 1px solid #aeaeae;
              border-radius: 50%;
              width: 100% !important;
              height: 100% !important;
            }
          }
        }
      }

      &__prices {
        display: none;
      }

      &__data {
        // width: 50%;
        max-width: calc(100% - (165px + 175px));

        &__title {
          // max-width: calc(100% - 24px);
          height: fit-content;
          margin: 0;
        }

        .expand-product {
          margin-bottom: 8px !important;

          a {
            color: $primary;

            svg {
              margin-left: 8px;
            }
          }
        }
      }

      &__attributes {
        max-width: 100%;
        width: 100%;
        min-height: 96px;
        margin-top: 8px !important;

        .product-attribute {
          border: none;
        }
      }

      &__description {
        max-width: 100%;
        width: 100%;
      }

      &__footer {
        display: block;
        margin-left: auto;

        &__ttw-code {
          font-size: 12px;
          color: #aeaeaa;
        }
      }
    }

    @media screen and (max-width: $sm-max-width) {
      padding: 10px !important;

      .product-card {
        &__image {
          max-width: 100%;
          margin-right: 0;
          display: flex;

          &__badges {
            left: 0;
            top: 0;
          }

          &__title {
            width: 80%;
            a {
              font-size: 14px !important;
            }
            display: block !important;

            .product-card__data {
              &__title {
                display: block !important;
              }

              &__divider {
                display: none !important;
              }

              &__supplier {
                display: block !important;

                &__icons {
                  display: none;
                }
              }
            }
          }
        }

        &__data {
          max-width: fit-content;
          .expand-product {
            display: none;
          }

          &__supplier {
            display: none !important;
          }

          &__title {
            display: none !important;
          }
        }
        &__attributes {
          min-height: fit-content;

          .attr_name {
            width: fit-content !important;
            margin-right: 8px;
          }

          .option {
            margin-bottom: 4px;
          }
        }
        &__footer {
          display: none;
        }
      }
    }
  }
}
