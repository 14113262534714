@import 'layout';
@import 'product-attributes';
@import 'products-tags';
@import 'product-description';

.product-details {
  .product-details__wrapper {
    padding-bottom: 40px;
  }

  @import 'product-gallery';
  @import 'product-title';
  @import 'product-manufacturer';
  @import 'product-description';
  @import 'product-attributes';
  @import 'product-prices';
  @import 'add-to-cart';
  @import 'products-tags';
  @import 'seek-trade-support';

  .product-gallery {
    .ant-carousel .slick-dots-bottom {
      bottom: -42px;
    }

    .ant-carousel .slick-dots li {
      width: 14px !important;
      height: 14px !important;

      button {
        width: 14px !important;
        height: 14px !important;
      }
    }
  }

  .badges {
    max-width: fit-content;
    position: absolute !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    z-index: 10;
    .product-card {
      &__image {
        &__badges {
          &__new-arrival {
            max-width: 82px;
            padding: 8px 24px;
            font-size: 12px;
          }
          &__discount {
            max-width: 82px;
            padding: 8px 24px;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.product-gallery--modal__container {
  max-width: 60vw !important;
  width: auto !important;

  .ant-modal-content {
    @include card-shadow();
  }

  .ant-modal-body {
    background-color: #f7f7f7 !important;
  }

  .ant-carousel {
    padding-bottom: 0px !important;
  }
}

@import 'seller-showroom';

.ant-modal-mask,
.ant-modal-wrap {
  z-index: 90000;
}

.slick-slide {
  padding: 0 6px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.product-details__vertical {
  .product-gallery {
    max-height: 535px;

    .ant-carousel img {
      height: auto;
      max-width: 100%;
    }
  }

  .subtitle {
    max-width: 360px;

    .d-flex {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .add-to-cart {
    background: #fff;
  }

  .product-manufacturer__vertical {
    .product-manufacturer {
      padding: 16px;
      width: 100%;
      border: 1px solid #ededed;
      border-radius: 4px;
      background: #fff;
    }
  }
}

@import 'product-details-responsive';
