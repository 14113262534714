.promotion-banner {
  padding: 20px;
  justify-content: center;
  gap: 20px;

  &__card {
    @include card-shadow();

    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
    cursor: pointer;

    &__icon {
      width: fit-content;

      svg {
        width: 48px;
        height: 48px;
      }
    }

    &__title {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 700;
      margin: 0px 0px 0px 16px;
      max-width: 150px;
    }

    &__description {
      margin-top: 12px;
      flex-grow: 2;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      color: #808080;
    }
  }

  @media (min-width: 768px) {
    &__card {
      margin-bottom: 0;
      max-width: 20.875rem;
      width: 100%;

      &__icon {
        svg {
          width: 70px;
          height: 70px;
        }
      }

      &__title {
        font-size: 18px;
        max-width: 190px;
      }

      &__description {
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }

  @media (min-width: 1038px) {
    &__card {
      max-height: 120px;
      display: grid;
      grid-template-rows: 1fr 60px;
      grid-template-columns: 70px 1fr;
      column-gap: 24px !important;
      padding: 20px 40px;
      max-width: 30.25rem;

      &__icon {
        grid-row-start: 1;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 1;

        svg {
          width: 80px;
          height: 80px;
        }
      }

      &__title {
        max-width: fit-content;
        font-size: 18px;
        margin: 0px;

        .mobile-breakpoint {
          display: none;
        }
      }

      &__description {
        margin-top: 0;
        font-size: 14px;
        line-height: 20px;
      }

      &__title,
      &__description {
        margin-left: 10px;
      }
    }
  }

  @media (max-width: 300px) {
    &__card {
      justify-content: center;

      &__title {
        text-align: center;
        margin-top: 12px;
        margin-left: 0;
      }
    }
  }
}
