.sellerShowroom {
  margin-top: -28px;
  background-color: #fff;

  &__bg-wrapper {
    background: #f7f7f7;
  }

  &__seller-showroom {
    position: relative;
    background-color: #fff;

    .container {
      position: relative;
    }

    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      background: white;
      z-index: 0;
    }
  }

  .sellerShowroom__head {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 0;
    height: 160px;
    background-color: $primary;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 50%);
    }

    .sellerShowroom__head-uploadWrapper {
      position: absolute;
      top: 90px;
      right: 30px;
      cursor: pointer;
      box-shadow: $shadow-ant;

      .upload {
        position: relative;
        z-index: 1;

        input[type='file'] {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 3;
          opacity: 0;
        }

        .uploadIcon {
          background: #fff;
          padding: 8px;
          border-radius: 50%;

          svg {
            width: 26px;
            height: 26px;
          }
        }
      }
    }

    h1 {
      display: none;
      text-align: left;
      margin-top: 0;
      margin-bottom: 16px;
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 56px;
      color: #ffffff;
      text-shadow: 0 0 1px #000;
    }

    h4 {
      display: none;
      text-align: left;
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 33px;
      color: #ffffff;
      text-shadow: 0 0 1px #000;
    }

    &-full {
      padding: 140px 0;
      height: auto;

      h1 {
        display: block;
      }

      h4 {
        display: block;
      }
    }
  }

  .sellerShowroom__title {
    margin-top: -60px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .logo {
      position: relative;
      width: 120px;
      height: 120px;
      border-radius: 60px;
      background: #ffffff;
      border: 1px solid #ededed;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      overflow: hidden;

      img {
        height: auto;
        max-width: 80%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .name {
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 44px;
      color: #004d40;
    }

    .company-description {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #000000;
      margin-top: 16px;
      margin-bottom: 28px;
    }

    @media screen and (max-width: 1024px) {
      margin: 24px 0 12px;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      flex-wrap: wrap;

      .logo {
        width: 80px;
        height: 80px;
        border-radius: 40px;
        margin-right: 1.2rem;
        img {
          height: 60px;
        }
      }

      .name {
        font-size: 24px;
        line-height: 32px;
      }

      .company-description {
        flex-basis: 100%;
        flex-grow: 2;
      }
    }
  }

  .sellerShowroom__body {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .companyInfo {
      flex-basis: 280px;
      margin-right: 28px;
    }

    .sellerShowroom__products {
      flex-basis: calc(100% - 308px);
    }
  }

  .sellerShowroom__left {
    padding: 0;
    margin-bottom: 20px;

    .sellerShowroom__viewCompanyFacts {
      display: block;
      width: 100%;
      margin-top: 20px;
      text-align: center;
      text-decoration: none;
      padding: 14px;
      border: 2px solid #004d40;
      border-radius: 4px;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 1.25px;
      text-transform: uppercase;
      color: #004d40;
      transition: all 0.3s ease;

      &:hover {
        background: #004d40;
        color: #fff;

        svg {
          path {
            fill: #fff;
            transition: all 0.3s ease;
          }
        }
      }
    }

    .sellerShowroom__card {
      padding: 20px;
      margin-bottom: 12px;
      background: #ffffff;
      border: 1px solid #ededed;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 5px;

      &:last-child {
        border-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .sellerShowroom__title {
        margin-top: 0;
        margin-bottom: 20px;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.15px;
        color: #000000;
      }

      .sellerShowroom__location {
        img {
          max-width: 100%;
          height: auto;
        }
      }

      .sellerShowroom__info {
        .sellerShowroom__infoWrapper {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }

          > div {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 19px;
              color: #4f4f4f;
              margin-bottom: 0 !important;

              &:first-child {
                padding-right: 12px;
              }

              strong {
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.1px;
                color: #004d40;
              }
            }
          }
        }
      }

      .sellerShowroom__categories {
        .sellerShowroom__category {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: nowrap;
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }

          .sellerShowroom__categoryIcon {
            width: 40px;
            height: 40px;
            flex: 0 0 40px;
            margin-right: 12px;
            text-align: center;
            padding: 6px;
            background: #defcec;
            border-radius: 20px;

            img {
              height: 28px;
              width: auto;
            }
          }

          .sellerShowroom__categoryName {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #004d40;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .filters-mobile {
      display: block !important;
    }
  }

  @media screen and (min-width: 1024px) {
    .filters-mobile {
      display: none;
    }
  }
}

@media screen and (max-width: 1024px) {
  .sellerShowroom .sellerShowroom__body {
    flex-direction: column;
  }

  .products {
    width: 100% !important;
    flex-basis: 100% !important;

    .card-list--grid {
      @include responsive-grid(2, 3, 4, 5);
    }
  }

  .sellerShowroom .sellerShowroom__body .companyInfo {
    flex-basis: 100%;
    margin-right: 0;
  }

  .sellerShowroom .sellerShowroom__body .sellerShowroom__products .products {
    flex-basis: 100%;
  }

  .filters-mobile {
    &__drawer {
      &__header {
        &__active-filters {
          display: flex !important;
        }
      }
    }
  }
}
