.account-info {
  margin: 0 auto;
  max-width: 390px;
  text-align: center;

  h2 {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    margin-bottom: 24px;
  }

  .account-info__recaptcha {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .account-info__policies-disclaimer {
    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    a {
      color: $primary;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-decoration: none;
    }
  }

  p {
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  button {
    margin: 24px 0;
    letter-spacing: 1.25px;
  }
}
