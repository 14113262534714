@import '../../../../scss/common/mixins';

.arrow-btn {
  @include card-shadow();

  border: 1px solid #fff;
  border-radius: 50%;
  width: fit-content !important;
  height: fit-content !important;

  transition: all 0.3s ease-in-out;

  &__icon {
    svg {
      margin-top: -4px;
      width: 20px;
      height: 20px;
      width: 100%;
      height: 100%;
    }
  }

  &--small {
    padding: 8px !important;
    background-color: #fff !important;
    border: 1px solid #efefef !important;

    svg {
      color: #b2f1d2;
    }

    &:hover {
      background-color: #defcec !important;
    }

    &:active {
     color: #fff !important;
     background-color: #004d40 !important;
    }
  }

  &--large {
    padding: 12px !important;
    background-color: #aeaeae !important;
    border: 1px solid #cccccc !important;

    svg {
      color: #fff;
    }

    &:hover {
      background-color: #fff !important;
      svg {
        color: #69F0AE !important;
      }
    }

    &:active {
      svg {
        color: #004d40 !important;
      }
      background-color: #fff !important;
    }
  }

  &--disabled {
    cursor: not-allowed !important;
  }
}
