.role-selection-card {
  @include card-shadow();

  position: relative;
  text-align: center;
  border-radius: 4px;
  border: 2px solid var(--base-base-02, #f7f7f7);
  padding: 20px;
  height: 250px;
  cursor: pointer;
  transition: all ease 500ms;

  input {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 18px;
    height: 18px;
    accent-color: $primary;
    cursor: pointer;
  }

  .role-selection-card__text {
    padding-top: 16px;

    h3 {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      color: $primary;
    }

    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: var(--text-icons-text-03, #808080);
    }
  }
}
