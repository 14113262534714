.secured-login {
  margin: 80px 0;
  font-family: $base-font-family;

  .login__image {
    img {
      max-width: 100%;
      height: auto;
      margin-left: 188px;
    }
  }

  .login__form {

    h1 {
      margin-top: 0;
      margin-bottom: 14px;
      font-weight: 700;
      font-size: 36px;
      line-height: 44px;
      color: #000;
    }

    p {
      margin-bottom: 22px;
      font-size: 16px;
      line-height: 20px;
      color: #000;
    }

    a {
      color: $primary;
      font-weight: 700;
      text-decoration: none;
    }

    .ant-form-item:nth-of-type(2) {
      margin-bottom: 12px !important;
    }

    &__password-reset {
      display: inline-block;
      margin-bottom: 20px;
    }
  }
}
