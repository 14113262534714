@media screen and (max-width: 1024px) {
  .product-details .product-gallery .ant-carousel img {
    height: auto;
    max-width: 100%;
  }

  .product-details .product-gallery {
    height: auto;
  }

  .product-details .product-details__title .subtitle .d-flex {
    flex-direction: row;
    justify-content: center !important;
    flex-wrap: wrap;

    .d-flex {
      margin-bottom: 24px;
    }

    .ms-auto {
      flex-basis: 100%;
      margin-left: 0;
    }
  }

  .product-details .product-attributes {
    margin-top: 20px;
  }

  .product-details .product-attributes .product-attribute .attr-options {
    flex-wrap: wrap;
  }

  .product-details .product-tags ul {
    flex-wrap: wrap;

    .tag {
      margin-bottom: 8px;
    }
  }
}
