.company-profile {

  .card {

    .card__content {

      .p-card__subtitle {
        margin: 12px 0;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #004D40;
      }

      .history-data {

        .history-item {
          margin-left: 9px;
          padding-left: 24px;
          position: relative;
          border-left: 2px solid #004D40;
          padding-bottom: 20px;

          &:before {
            content: "";
            position: absolute;
            left: -10px;
            top: 0;
            width: 18px;
            height: 18px;
            background: #FFF;
            border-radius: 9px;
            border: 2px solid #004d40;
          }

          &:last-child {
            padding-bottom: 0;
            border-left: 2px solid transparent;
          }

          .item-content {
            position: relative;

            h3 {
              margin-top: 0;
              margin-bottom: 4px;
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 18px;
              color: #004D40;
            }

            p {
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 16px;
              color: #000000;
              margin-bottom: 0;
            }
          }
        }
      }

      .additional-info {

        p {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.1px;
          color: #808080;

          strong {
            padding-left: 12px;
            font-weight: 600;
            color: #000000;
          }
        }
      }

      .brands {

        .brand {
          text-align: center;

          &__image {
            width: 78px;
            height: 78px;
            display: block;
            margin: 0 auto;
            position: relative;
            border: 1px solid #EDEDED;
            overflow: hidden;
            margin-bottom: 12px;

            img {
              max-width: 100%;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }

          h5 {
            margin-bottom: 0;
            margin-top: 0;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: #000000;
          }
        }
      }

      .documents {

        .document {

          a {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: #004D40;
            text-decoration: none;
          }

          .doc-icon {
            width: 32px;
            height: 32px;
            background: #80CBC5;
            border-radius: 16px;
            position: relative;
            margin-right: 12px;

            i {
              color: #FFF;
              position: absolute;
              font-size: 18px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          .doc-name {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: #000000;
          }
        }
      }

      .company-photos {

        .photo {
          flex: 0 0 80px;
          height: 80px;
          width: 80px;
          margin-right: 12px;
          margin-bottom: 12px;
          border: 1px solid #EDEDED;
          border-radius: 4px;
          overflow: hidden;
          position: relative;

          img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      h5 {
        margin-top: 0;
        margin-bottom: 16px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #000000;

        img {
          width: 60px;
          height: 60px;
          margin-right: 12px;
        }

        span {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.1px;
          color: #808080;
          padding-left: 16px;
        }
      }

      .p-card {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #000000;
      }

      .p-card__icon {
        display: flex;

        i {
          color: $primary-color;
          margin-right: 8px;
          font-size: 18px;
          line-height: 1;
        }
      }

      .company-industries {

        .industry {
          margin-bottom: 12px;

          .industry__image {
            margin-right: 12px;

            img {
              width: 40px;
              height: 40px;
            }
          }

          .industry__name {

            h4 {
              margin-top: 0;
              margin-bottom: 4px;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              display: flex;
              align-items: center;
              letter-spacing: 0.1px;
              color: #000000;
            }

            .sub-industries {
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.1px;
              color: #808080;
            }
          }
        }
      }

      .company-branch {
        padding: 10px;
        background: #F7F7F7;
        border-radius: 4px;
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
