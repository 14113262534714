@import '../../../../../scss/common/mixins';

.filters-mobile {
  &__button {
    padding: 3.2px 12px 3.2px 8px;
    margin-left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    @include card-border();
    border-color: #ccc;

    cursor: pointer;

    svg {
      padding: 4px;
      color: #26a69b;
      width: 24px;
      height: 24px;
    }
  }

  &__drawer {
    .ant-btn-primary {
      letter-spacing: 1.25px !important;
    }
    .ant-drawer-body {
      padding: 16px 20px 0px !important;
    }
    .ant-collapse {
      background-color: #fff;
      border: none !important;

      &-header {
        padding-left: 0px !important;
        font-weight: 700;
      }

      &-item {
        margin-bottom: 16px !important;
      }

      &-arrow {
        svg {
          color: #aeaeae !important;
        }
      }
    }

    &__header {
      margin: 0px 0px 26px;
      display: flex;
      justify-content: start;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px;

      h3 {
        font-size: 24px;
        font-weight: 700;
      }

      &__clear-btn {
        color: #aeaeae;
        cursor: pointer;

        &--active {
          color: #26a69b;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    &__drawer {
      .ant {
        &-collapse {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: start;
          flex-wrap: wrap;

          &-item {
            width: 33%;
            border: none !important;
          }
        }
        &-drawer {
          &-content {
            &-wrapper {
              height: 50vh !important;
            }
          }

          &-footer {
            text-align: center;
            .ant-btn {
              width: 50% !important;
            }
          }
        }
      }
    }
  }
}
