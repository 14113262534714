.tags {
  max-width: 100%;
  display: flex;
  gap: 8px;
  font-size: 12px;
  font-weight: 400;
  color: #26a69b;

  &__tag {
    cursor: pointer;
  }
}
