.promoBanner {
  background: rgba(224, 242, 241, 0.6);
  padding: 28px 0;

  .banner {

    .icon {
      margin-bottom: 12px;

      svg {
        width: 40px;
        width: 40px;
      }
    }

    h3 {
      margin-top: 0;
      margin-bottom: 12px;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.15px;
      color: #000000;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #808080;
      margin-bottom: 16px;
    }

    a {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 1px;
      text-decoration-line: underline;
      color: #004D40;
    }
  }
}

@media screen and (max-width: 1024px) {
  .promoBanner {

    .banner {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
