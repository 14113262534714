.add-to-cart {
  border: 1px solid #ededed;
  border-radius: 4px;
  margin-top: 28px;
  padding: 16px;

  .cart-nav {
    .sign-in-text {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .sign-in-icon {
        margin-right: 8px;

        svg {
          width: 24px;
          height: 24px;
        }
      }

      .text {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #000000;

        strong {
          color: #00968a;
          margin-right: 8px;
        }

        &__available-qty {
          font-size: 14px;
          letter-spacing: 0.4px;
          color: #808080;
          margin-bottom: 2px;
        }
      }
    }

    .price {
      .price-text {
        margin-bottom: 0;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.15px;
        color: #000000;
        text-align: left;

        > span {
          display: block;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.25px;
          text-decoration-line: line-through;
          color: #808080;
          font-weight: 400;
        }
      }

      .save {
        margin-left: 12px;
        margin-top: -2px;

        img {
          height: 20px;
          width: 20px;
          margin-right: 5px;
        }

        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.4px;
          color: #fd3f55;
        }
      }
    }
  }

  .cart-btns {
    margin-top: 20px;

    button {
      width: 100%;
      border-radius: 4px;
      height: 44px;
      background: #004d40;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 1.25px;
      text-transform: uppercase;
      color: #ffffff;
      border: 2px solid #004d40;
    }

    .outlined {
      border: 2px solid #004d40;
      background: transparent;
      color: #004d40;
    }
  }

  .card-extras {
    margin-top: 24px;

    .card-extra {
      .anticon {
        vertical-align: 0;
      }

      svg {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.4px;
        color: #808080;
      }
    }
  }
}
