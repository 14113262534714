.ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  transition: background-color 0.3s;
  background: #EDEDED;
  border-radius: 100px;
}

.ant-slider-track {
  position: absolute;
  height: 4px;
  background: #00968A;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.ant-slider-handle {
  border-color: #00968A;
  background: #00968A;
}
