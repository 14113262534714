.brandsAndCertificates {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 24px;

  .cardsWrapper {
    margin-right: 24px;
    flex: 0 0 182px;
    width: 182px;
  }

  .certificates {
    margin-right: 0;
    flex: 0 0 calc(100% - 206px);
    width: calc(100% - 206px);
  }
}

@media screen and (max-width: 800px) {
  .brandsAndCertificates {
    flex-direction: column;

    .cardsWrapper {
      margin-right: 0;
      flex: 0 0 100%;
      width: 100%;
      margin-bottom: 28px;
    }

    .certificates {
      flex: 0 0 100%;
      width: 100%;
    }
  }
}
