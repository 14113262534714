.ant-badge {
  a {
    margin-right: 0px !important;
  }

  &-count {
    box-shadow: none;
    background-color: $secondary-color;
    color: $primary-color;
    padding: 0 8px;
    height: 18px !important;

    p {
      font-size: 12px;
      font-weight: 700;
    }
  }
}
