@import '../../../../../../../scss/common/mixins';

$caption-color: #aeaeae;
$selector-width: 147px;
$selector-height: 32px;
$selector-line-height: 30px;

.sort-controller-desktop {
  &__caption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $caption-color;
  }

  &__select {
    .ant-select-selector {
      @include size($selector-width !important, $selector-height !important);
    }
    .ant-select-selection-item {
      line-height: $selector-line-height !important;
    }
    .ant-select-arrow {
      top: 53% !important;
    }

    .ant-select-selection-placeholder {
      color: #000 !important;
      text-transform: capitalize;
      line-height: $selector-line-height !important;
    }
  }
}
