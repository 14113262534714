.explore {
  margin: 0px 0px 80px 0px;
  padding: 0px 120px;

  .ant-btn-primary {
    max-height: 40px;
  }

  @media screen and (max-width: '719px') {
    padding: 0 20px;

    .ant-btn-primary {
      width: 100%;
    }
  }

  @media screen and (min-width: '720px') and (max-width: '1439px') {
    padding: 0px 40px;
  }

  &__header {
    &__breadcrumbs {
      &__item {
        max-width: fit-content;
        display: flex;
        align-items: center;
        gap: 6px;
        color: $text-gray-1;
        text-decoration: none;

        p {
          margin: 0px;
          font-weight: 700;
        }

        svg {
          height: 8px;
          width: 8px;
          margin: 2px 0px 0px 0px;
        }
      }
    }

    &__title {
      h5 {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
      }

      @media screen and (max-width: '719px') {
        h5 {
          margin: 24px 0 12px 0;
        }
      }
      @media screen and (min-width: '720px') {
        margin: 32px 0px 46px;
        display: flex;
        justify-content: space-between;
        align-content: center;

        h5 {
          margin: 0;
        }
      }
    }
  }

  &__list {
    @include responsive-grid(2, 4, 6, 8);

    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: stretch;
    align-content: flex-start;
    flex-wrap: wrap;
    gap: 28px;

    &--brands,
    &--countries {
      .explore__card {
        justify-content: space-between;
      }
    }

    &--brands {
      img {
        border-radius: 4px;
        border: 1px solid #EDEDED;
      }

      &__text {
        padding: 8px;
      }
    }

    &--countries {
      .explore__card {
        height: 176px;
      }
    }

    @media screen and (max-width: '719px') {
      margin: 24px 0px 0px 0px;
      gap: 10px;
      font-size: 0.8rem;

      .explore__card {
        max-width: 100%;
        margin: 8px auto;

        img {
          max-width: 100px;
        }
      }
    }

    @media screen and (min-width: '720px') {
      gap: 20px;

      .explore__card {
        max-width: 178px;
        margin: 0px auto;
      }
    }

    @media screen and (min-width: '1440px') {
      gap: 20px;

      .explore__card {
        max-width: 100%;
        margin: 0px auto;
      }
    }
  }

  &__card {
    @include card-shadow();

    max-width: 178px;
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    gap: 12px;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;

    p {
      margin-bottom: 0px;
    }

    img {
      max-width: 200px;
      max-height: 200px;
      width: 100%;
      height: 100%;
    }

    &__icon {
      background-color: #defcec;
      border-radius: 50%;
      padding: 12px;
    }

    &__description {
      color: $text-gray-1;
      font-size: 12px;
    }

    &--unavailable {
      img {
        filter: grayscale(100%);
      }
    }
  }
}
