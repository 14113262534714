.search__list {

  &__card {
    display: flex;
    justify-content: start;

    img {
      max-width: 140px;
      max-height: 140px;
    }
  }
}
