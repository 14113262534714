.filters {
  flex: 0 0 280px;
  margin-right: 28px;

  .filters-wrapper {

    .ant-collapse {
      background-color: transparent;
      border: 0;
      border-radius: 0;
    }

    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
      padding: 18px 18px 18px 0;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;

      color: #000000;

      .anticon {
        vertical-align: 0;
      }
    }

    .ant-collapse-content {
      border-top: 0;

      .ant-collapse-content-box {
        padding: 0;
        margin-bottom: 16px;

        .ant-checkbox-group {

          .ant-checkbox-group-item {
            display: flex;
            margin-bottom: 12px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        input {
          border: 1px solid #CCCCCC;
          border-radius: 4px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          display: flex;
          align-items: center;
          color: #000;
          padding: 10px 16px;
        }
      }
    }

    .ant-collapse > .ant-collapse-item {
      border-bottom: 1px solid #EDEDED;

      &:last-child {
        border-bottom: 0;
      }
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      padding-top: 16px;

      svg {

        path {
          fill: #AEAEAE;
        }
      }
    }
  }
}
