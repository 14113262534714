.product-gallery--modal__container {
  @media (max-width: 480px) {
    display: flex !important;
    justify-content: center !important;

    .arrow-btn {
      display: none !important;
    }

    .ant-modal-body {
      width: 90vw;
    }

    .slick-track {
      display: flex !important;
      align-items: center !important;
    }
  }
}
