// Auth
@import "auth/header";

.secured-main {
  padding: 0 15px;
  position: relative;
  @include header-spacing;
}

@import "verification-layout";

.ttw-layout {
  position: relative;
  padding-left: 240px;
  min-height: 100vh;
  background: #F6F7F8;

  .main-layout-header {
    padding: 16px 30px;

    .header-user-menu {
      cursor: pointer;

      .user-dropdown {
        width: 32px;
        height: 32px;
        background: #80CBC5;
        border-radius: 16px;
        position: relative;
        margin-right: 12px;

        i {
          color: #FFF;
          position: absolute;
          font-size: 18px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .layout__content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .ttw-main {
      padding: 30px;
      flex: 1 1;
    }

    .ttw-footer {
      border-top: 1px solid #B2DAD6;
      padding: 20px 30px;

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.01em;
        color: #BDBDBD;
        margin-bottom: 0;
      }
    }
  }

  .ttw-sidebar {
    position: fixed;
    left: 0;
    top: 0;
    width: 240px;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.07);

    .sidebar__logo {
      padding: 20px 24px;

      img {
        width: 88px;
        height: auto;
      }
    }

    .sidebar__menu {
      margin-top: 20px;

      li.ant-menu-item {
        padding: 0 8px !important;
        margin: 0 !important;
        height: auto;
        line-height: 1;
        background: transparent !important;

        a {
          width: 100%;
          padding: 14px;
          padding-bottom: 12px;
          border-radius: 4px;
          text-decoration: none;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          letter-spacing: 0.01em;
          color: #4F4F4F;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: flex-start;

          span {
            display: block;
            line-height: 20px;
          }

          .fe {
            margin-right: 14px;
            font-size: 18px;
            margin-top: -4px;
          }
        }
      }

      li.ant-menu-submenu {
        margin: 0 !important;
        padding: 0 8px !important;
        height: auto;
        line-height: 1;
        background: transparent !important;

        .ant-menu {
          background: transparent !important;

          .ant-menu-item {
            padding: 0 !important;
            margin: 0 !important;
            padding-left: 48px !important;
            background: transparent !important;

            a {
              display: block;
              padding: 0;
              line-height: 40px;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              color: #4F4F4F;
              padding-left: 12px;
              position: relative;

              &:hover {
                color: $primary-color;
              }

              span {
                line-height: 40px;
              }

              &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 4px;
                height: 4px;
                border-radius: 4px;
                background: #4F4F4F;
              }
            }
          }


          > .ant-menu-item.ant-menu-item-selected a {
            color: $primary-color;
          }
        }

        .ant-menu-submenu-title {
          width: 100%;
          padding: 14px;
          padding-bottom: 12px;
          border-radius: 4px;
          text-decoration: none;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          letter-spacing: 0.01em;
          color: #4F4F4F;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: flex-start;
          background: transparent !important;

          span {
            display: block;
            line-height: 20px;
          }

          .fe {
            margin-right: 14px;
            font-size: 18px;
            margin-top: -4px;
          }
        }
      }

      > .ant-menu-item.ant-menu-item-selected a {
        background: #B4F7D6;
        color: #4F4F4F;

        .fe {
          color: $primary-color;
        }
      }
    }

    .ant-menu-inline .ant-menu-selected::after, .ant-menu-inline .ant-menu-item-selected::after {
      background: transparent !important;
      border-color: transparent !important;
    }

    .ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow {
      color: #4F4F4F !important;
    }
  }
}
