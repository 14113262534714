.description-card {
  padding: 20px;
  background: #ffffff;
  border: 1px solid #ededed;
  margin-bottom: 12px;
  transition: all 0.2s ease;
  // max-width: calc(100% - 10%);

  &:last-child {
    margin-bottom: 0;
  }

  .card-title {
    margin-top: 0;
    margin-bottom: 21px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    transition: all 0.2s ease;
  }

  p,
  ul li,
  ol li {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #000000;
    transition: all 0.2s ease;
  }

  table {
    border: 0;
    transition: all 0.2s ease;

    tr {
      border: 0;
      transition: all 0.2s ease;

      &:nth-child(2n) {
        background: #f7f7f7;
      }

      td {
        border: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #000000;
        transition: all 0.2s ease;
      }
    }
  }

  .tags {
    transition: all 0.2s ease;
    margin-top: 22px;
    max-width: 100%;
    overflow-x: scroll;

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      transition: all 0.2s ease;
      // max-width: calc(100% - 40px);
      // flex-wrap: wrap;

      li {
        padding: 6px 12px;
        background: #e0f2f1;
        border-radius: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0;
        letter-spacing: 0.1px;
        margin-right: 8px;
        color: #004d40;
        transition: all 0.2s ease;

        &:last-child {
          margin-right: 0;
        }

        &:first-child {
          margin-right: 16px;
          padding: 0;
          background: transparent;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.1px;
          color: #aeaeae;
        }
      }
    }
  }
}

.description-card {
  background: #fff;
  padding: 24px 40px;
  border: 1px solid #ededed;

  .card-title {
    margin-top: 0;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #000000;
  }

  p,
  ul li,
  ol li {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #000000;
    margin-bottom: 10px;

    strong {
      display: contents;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__packaging {
    .product-details__packages {
      .package-item {
        padding-bottom: 5px;
        margin-bottom: 17px;
        border-bottom: 1px solid #ededed;

        &:last-child {
          border-bottom: 0;
          margin-bottom: 0;
        }

        .package-item__head {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 20px;

          .package-icon {
            margin-right: 8px;

            img {
              height: 44px;
              width: 44px;
              width: auto;
              margin-top: -2px;
            }
          }

          .package-name {
            margin-left: 12px;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: #000000;
            text-transform: capitalize;

            span {
              padding-left: 6px;
              color: #26a69b;
            }

            &__type {
              display: block;
              padding-left: 0px !important;
              font-size: 12px;
              font-weight: 600;
            }
          }
        }

        .package-item__content {
          p {
            margin-bottom: 12px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: #808080;

            span {
              padding-left: 12px;
              color: #000000;
            }
          }
        }
      }
    }
  }
}

.product-description {
  background: #f7f7f7;
  padding: 40px 0;
}

.product-details__table {
  width: 100%;

  tbody {
    tr {
      &:nth-child(2n) {
        background: #f7f7f7;
      }

      td {
        border-top: 0;
        padding: 6px 12px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        align-items: center;
        letter-spacing: 0.1px;
        color: #000000;

        &:last-child {
          border-left: 2px solid #fff;
        }
      }
    }
  }
}
