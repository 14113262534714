// Styling for all classes in the CompanyCard.jsx component
.company-card {
  @include flex-col();
  align-items: center;

  position: relative;

  max-height: 294px;
  max-width: 232px;
  width: 100%;
  padding: 8px 12px 12px;

  background-color: $white;
  transition: all 0.25s ease-in-out;
  cursor: pointer;

  @media screen and (min-width: 992px) {
    max-width: 240px;

    &:hover {
      transform: scale(1.05);
      z-index: 10;

      .company-card__footer {
        transform: translateY(0) scale(1.01);
        opacity: 1;
        box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.12);
        transition: transform 0.25s ease-in-out, opacity 0.25s ease-in;
      }
    }
  }

  @media screen and (max-width: 719px) {
    max-width: 162px;

    &__image {
      img {
        width: 148px !important;
        height: 152px !important;
      }
    }

    &__info {
      &__body {
        &__name {
          font-size: 14px !important;
        }
      }
    }
  }

  &__image {
    img {
      width: 220px;
      height: 220px;
      border-radius: $border-radius-md;

      object-fit: cover;
      cursor: pointer;
    }
  }

  &__info {
    @include flex-col();
    @include center-flex();

    width: 100%;

    &__body {
      width: 100%;
      margin: 8px 0 0 0;

      display: flex;
      align-items: center;
      gap: 8px;

      &__logo {
        img {
          width: 44px;
          height: 44px;
          border: 1px solid #ededed;
          border-radius: $border-radius-circle;

          object-fit: cover;
        }
      }

      &__name {
        font-size: 16px;
        width: 100%;

        strong {
          cursor: pointer;
        }

        &__country {
          display: flex;
          align-items: center;
          gap: 4px;

          p {
            margin: 0;
            font-size: 12px;
            font-weight: 400;
            color: $text-gray-1;
          }
        }
      }

      &__industries,
      &__brands,
      &__btn {
        display: none;
      }
    }
  }

  @media (max-width: 992px) {
    .company-card__footer {
      transform: translateY(0);
    }
  }

  &__footer {
    position: absolute;
    z-index: -10;

    top: calc(100% - 12px);
    width: 100%;
    padding: 0px 12px 12px;
    border: 1px solid #ededed;
    border-top: none;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    transform: translateY(-100%);

    background-color: $white;
    opacity: 0;

    // transition: transform 0.25s ease-in-out, opacity 0.4s ease-in;

    &__products-preview {
      &__list {
        margin: 12px 0;

        @include center-flex();
        gap: 8px;

        &__item {
          max-width: 48px;
          max-height: 48px;
          border: 1px solid #ededed;

          cursor: pointer;

          img {
            max-width: 100%;
            width: 100%;
          }

          &--etc {
            @include center-flex();

            width: 48px;
            height: 48px;

            padding: 8px;

            background-color: #f7f7f7;
            cursor: default;

            span {
              margin-top: -12px;
              font-size: 24px;
              font-weight: 700;
              color: $primary;
            }
          }
        }
      }

      &__btn {
        width: 100%;
        height: 36px;
      }
    }
  }

  &--horizontal {
    @include card-shadow();

    width: 100%;

    padding: 12px;
    margin-bottom: 12px;
    border: 1px solid #ededed;
    border-radius: 4px;
    background-color: #fff;

    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;

    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

    .company-card__image {
      max-width: 180px;
      max-height: 180px;
      width: 100%;
      height: 100%;

      margin-right: 24px;
      margin-bottom: 0;

      img {
        height: 180px;
        width: 180px;

        object-fit: cover;
      }
    }

    .company-card__info {
      max-height: 100%;
      width: 100%;
      height: 100%;

      justify-content: space-between;

      &__body {
        width: 100%;

        display: flex;
        flex-wrap: wrap;

        &__logo {
          max-width: 58px;
          max-height: 58px;

          img {
            width: 58px !important;
            height: 58px !important;
          }
        }

        &__name {
          width: 80%;

          margin-left: 12px;

          display: flex;
          align-items: center;
          gap: 8px;

          font-size: 18px;

          &__country {
            max-width: 20px;
            width: 100%;

            margin-bottom: -4px;

            img {
              width: 100%;
            }
            p {
              display: none;
            }
          }
        }

        .logo-list {
          padding: 0;
          margin: 0px 0px 0px 8px;

          display: flex;
          align-items: center;
          gap: 8px;

          list-style: none;

          &__logo {
            list-style: none;

            img {
              max-width: 30px;
              max-height: 30px;
              width: 100%;
              height: 100%;
            }
          }
        }

        &__industries,
        &__brands {
          width: 100%;

          display: flex;

          span {
            color: $text-gray-1;
          }
        }

        &__industries {
          margin-top: 8px;
        }

        &__brands {
          margin-top: 16px;

          .logo-list {
            margin: 0px 0px 0px 28px;

            &__logo {
              img {
                border: 1px solid #ededed;
              }
            }
          }
        }
      }
    }

    .company-card__footer {
      // reset the footer styles
      position: relative;
      opacity: 1 !important;
      transform: translateY(0) scale(1);
      transition: none;
      z-index: 1;
      border: none;

      max-width: 265px;
      padding: 8px 0px 0px 0px;

      &__products-preview {
        &__list {
          justify-content: flex-start;

          &__item {
            max-width: 80px;
            max-height: 80px;

            img {
              max-width: 100%;
              width: 100%;

              filter: grayscale(100%);
              transition: all 0.25s ease-in-out;

              &:hover {
                filter: grayscale(0%);
              }
            }

            &--etc {
              display: none;
            }
          }
        }

        &__btn {
          width: 100%;
          margin-top: 8px !important;
          height: 44px !important;

          color: $primary !important;
          background-color: $white !important;
          border: 2px solid $primary !important;

          &:hover {
            background-color: $primary !important;
            color: $white !important;
          }
        }
      }
    }

    @media screen and (max-width: 1024px) {
      .company-card__image {
        max-width: 120px;
        max-height: 120px;

        img {
          width: 120px;
          height: 120px;
        }
      }

      .company-card__info {
        &__body {
          &__name {
            max-width: 70%;
            font-size: 16px;
          }

          &__btn {
            width: 100%;

            margin-top: 8px;
            border: 2px solid $primary !important;

            display: block;

            letter-spacing: 1.1px;
            font-weight: 700;
          }
        }
      }

      .company-card__footer {
        display: none !important;
      }
    }

    @media screen and (max-width: 720px) {
      .company-card__info {
        &__body {
          &__logo {
            max-width: 32px;
            max-height: 32px;

            img {
              width: 32px !important;
              height: 32px !important;
            }
          }
          &__name {
            margin-left: 4px;

            font-size: 14px;

            &__country {
              img {
                margin-top: -2px;
              }
            }
          }
        }
      }
    }
  }

  &--home {
    &:hover {
      transform: none;
    }

    .company-card {
      &__footer {
        display: none;
      }
    }
  }
}
