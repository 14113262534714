@import '../variables';

@mixin header-spacing {
  padding-top: 28px;
}

@mixin responsive-grid($cellphone, $tablet, $desktop, $largeDesktop) {
  @media screen and (max-width: '480px') {
    display: grid;
    grid-template-columns: repeat($cellphone, minmax(0, 1fr));
  }
  @media screen and (min-width: '481px') and (max-width: '1439px') {
    display: grid;
    grid-template-columns: repeat($tablet, minmax(0, 1fr));
  }
  @media screen and (min-width: '1440px') and (max-width: '1919px') {
    display: grid;
    grid-template-columns: repeat($desktop, minmax(0, 1fr));
  }
  @media screen and (min-width: '1920px') {
    display: grid;
    grid-template-columns: repeat($largeDesktop, minmax(0, 1fr));
  }
}

@mixin card-shadow($hover: true) {
  transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  @if $hover {
    &:hover {
      box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.12);
    }
  }
}
@mixin card-border {
  border: 1px solid #ededed;
  border-radius: $border-radius-md;
}

@mixin flex-col {
  display: flex;
  flex-direction: column;
}

@mixin center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex($direction: row, $justify: space-between, $align: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin discount-badge {
  max-width: fit-content;
  border: 1px solid $primary;
  border-radius: 1px;
  padding: 0px 5px;
  font-size: 12px;
  color: $primary;
}

@mixin size($width, $height) {
  width: $width;
  height: $height;
}
