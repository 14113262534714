.product-gallery {
  height: 545px;
  overflow: unset;
  position: relative;

  .ant-carousel .slick-slider .slick-track,
  .ant-carousel .slick-slider .slick-list {
    text-align: center;
  }

  .ant-carousel {
    padding-bottom: 0px !important;
    .ant-image--hoverable {
      &::before {
        transition: all 0.3s ease-in-out;
        opacity: 0;
        content: 'Preview';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 0.8rem;
        text-transform: uppercase;
        background: rgba(0, 0, 0, 0.5);
        padding: 10px 20px;
        border-radius: 4px;
      }

      &:hover {
        position: relative;
        cursor: pointer;

        &::before {
          opacity: 1;
        }
      }
    }
    img {
      height: 545px;
      width: auto;
      cursor: pointer;

      // On hover has a overlay with Preview text
    }
  }

  .ant-carousel .slick-dots li {
    width: 10px;
    height: 10px;
    background: transparent;
  }

  .ant-carousel .slick-dots li button {
    display: block;
    width: 100%;
    height: 10px;
    border-radius: 10px;
    border: 1px solid #ededed;
    background: transparent;
    opacity: 1;
  }

  .ant-carousel .slick-dots li.slick-active button {
    background: #aeaeae;
    border: 1px solid #fff;
    opacity: 1;
  }

  .ant-carousel .slick-arrow {
    top: 50%;

    &.slick-prev,
    &.slick-next {
      margin: 0;
      color: #000;
      font-size: 1rem;
      line-height: 1;
      content: '' !important;
      // z-index: 1000000000;

      &:before {
        content: '' !important;
      }
    }

    &.slick-prev {
      left: 0;
      z-index: 10 !important;
    }
    &.slick-next {
      right: 0;
    }
  }

  &--modal {
    max-height: 80vh;
    height: auto;
    background-color: #fff;

    .ant-carousel .slick-slider .slick-track,
    .ant-carousel .slick-slider .slick-list {
      text-align: center;
    }

    .ant-carousel {
      img {
        max-height: 80vh;
        object-fit: cover !important;
      }
    }

    .ant-carousel .slick-arrow {
      top: 50%;

      &.slick-prev,
      &.slick-next {
        margin: 0;
        color: #000;
        font-size: 1rem;
        line-height: 1;
        content: '' !important;
        // z-index: 1000000000;

        &:before {
          content: '' !important;
        }
      }

      &.slick-prev {
        left: -24%;
        z-index: 10 !important;
      }
      &.slick-next {
        right: -24%;
      }
    }
  }
}
