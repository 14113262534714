.search-bar {
  max-width: 520px;

  .ant-input-affix-wrapper {
    padding: 3px 3px 3px 20px;
  }
  &__input {
    border-radius: 28px;

    &__enter-button {
      max-height: 40px;
      border-radius: 28px;

      &__text {
        display: none;
      }

      @media screen and (min-width: 720px) {
        padding: 10px 30px;
        display: flex;
        align-items: center;
        gap: 10px;

        &__text {
          display: none;
          display: inline-block;
        }
      }
    }
  }
  &__overlay {
    border-radius: 8px;
    border: 1px solid #ededed;
    background-color: $white;

    &__tabs {
      .ant-tabs-nav {
        padding: 0 12px;
      }

      &__loader {
        display: flex !important;
        justify-content: center;
        padding: 24px;
        opacity: 0.6;
        span {
          background-color: $primary-color !important;
        }
      }

      &__opt-list {
        max-height: 200px;
        margin: 0;
        padding: 0;
        list-style: none;
        overflow-y: scroll;
        scrollbar-width: thin;

        &__item {
          padding: 10px 15px;
          list-style: none;
          cursor: pointer;

          &:hover {
            background-color: #f7f7f7;
          }
        }
      }
    }
  }
}
