.ant-btn {
  line-height: 1.5715;
  height: 52px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 4px;
  color: #000;
  font-weight: 700;
}

.ant-btn-ttu {
  text-transform: uppercase;
}

.ant-btn--spread {
  letter-spacing: 1.25px;
}

.ant-btn-link {
  text-decoration: none !important;
}

.ant-btn-sm {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 32px;
  height: 34px;
  border-radius: .2rem;
}

.add-btn {
  padding: 0;
  margin: 0;
  box-shadow: none;
  outline: none;
  border: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: $primary-color;
  background: transparent;
  height: auto;

  i {
    padding-right: 6px;
  }

  &:hover {
    color: $primary-color;
    background: transparent;
  }
}

.add-btn-link {
  text-decoration: none;
  outline: none;
}

.ant-btn-link {
  padding: 0;
  margin: 0;
  background: transparent;
  box-shadow: none;
}

.ant-btn-primary {
  color: #FFF !important;
  background: $primary-color !important;
  border-color: $primary-color !important;

  &:hover {
    background: lighten($primary-color, 5%) !important;
    border-color: lighten($primary-color, 5%) !important;
  }
}

.ant-btn-primary__outlined {
  background: transparent !important;
  color: $primary-color !important;

  &:hover {
    background: transparent !important;
    color: lighten($primary-color, 5%) !important;
    border-color: lighten($primary-color, 5%) !important;
  }
}

.ant-btn-dashed {
  border-style: solid !important;
}
