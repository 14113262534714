@import '../../../../../../../scss/common/mixins';

.sort-controller-mobile {
  &__button {
    width: 32px;
    height: 32px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include card-border();
    border-color: #ccc;
    cursor: pointer;

    svg {
      padding: 4px;
      width: 22px;
      height: 22px;
    }
  }

  &__drawer {
    ul, li {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    &__title {
      h3 {
        margin: -4px 0px 24px;
        font-size: 18px;
        font-weight: 700;
      }
    }

    &__options {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;

      &__option {
        border: 2px solid #EDEDED;
        border-radius: 8px;
        text-align: center;
        cursor: pointer;

        p {
          margin: 0;
          padding: 6px 0px;
          font-size: 14px;
          font-weight: 500;
        }

        &--active {
          border-color: #004d40;
        }
      }
    }
  }
}
