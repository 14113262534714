.product-details__title {
  h1 {
    margin-top: 0;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #000000;
  }

  .subtitle {
    .product-rate {
      margin-right: 8px;

      .ant-rate {
        .ant-rate-star:not(:last-child) {
          margin-right: 2px;
        }

        svg {
          height: 20px;
          width: 20px;
        }
      }
    }

    .badges {
      margin-right: 16px;


      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        li {
          margin-right: 16px;

          &:last-child {
            margin-right: 0;
          }

          img {
            max-height: 20px !important;
            max-width: 20px !important;
          }
        }
      }
    }

    .text {
      padding-top: 2px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.4px;
      color: #aeaeae;
    }
  }
}
