@import '../../../../scss/variables';
@import '../../../../scss/common/mixins.scss';

.carousel {
  .ant-carousel {
    .slick-next {
      right: 30% !important;
    }
    .slide-prev {
      left: 30% !important;
    }

    .slick-slide:has(.certificateWrapper) {
      width: 132px !important;
    }
  }

  .item {
    width: 100% !important;
    height: 160px;
    user-select: none;

    .itemWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      gap: 12px;
      // padding: 12px;
      @include card-border();

      // itemWrapper must must main an aspct ratio of a rectangle in computer screens and tablets screeens, and a square in cellphone screens

      img,
      svg {
        width: 100%;
        height: auto;

        @media screen and (max-width: 768px) {
          width: auto;
          height: 150px;
        }
      }

      .antImage {
        &::before {
          transition: all 0.3s ease-in-out;
          opacity: 0;
          content: 'Preview';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 50%;
          left: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          transform: translate(-50%, -50%);
          color: #fff;
          font-size: 0.8rem;
          text-transform: uppercase;
          background: rgba(0, 0, 0, 0.5);
          padding: 10px 20px;
          border-radius: 4px;
          z-index: 100;
        }

        &:hover {
          position: relative;
          cursor: pointer;

          &::before {
            opacity: 1;
          }
        }
      }

      .image {
        height: 100%;
        object-fit: cover;
      }

      svg {
        top: 40%;
        width: 70px;
        height: 70px;
      }

      p {
        width: 100%;
        text-align: center;
        margin-bottom: 0;
        font-size: 12px;
        line-height: 16px;
        padding: 0 8px;
        letter-spacing: 0.4px;
        color: #4f4f4f;

        a {
          text-decoration: none;
        }
      }

      > div {
        width: 100%;
        height: 100%;

        > div svg {
          top: 35%;
          width: 30px;
          height: 30px;
        }
      }
    }

    .certificateWrapper {
      padding: 8px 6px;
      background: #f7f7f7;
      border: 1px solid #ededed;
      max-width: 132px;
      width: 132px !important;
      overflow-y: scroll;

      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }

      svg {
        width: 60px;
        height: auto;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
        height: 100%;
      }
    }
  }

}
