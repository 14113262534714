.cart-popup {
  width: 396px;
  margin-top: 26px;
  margin-right: 8px;
  background-color: $white;
  box-shadow: $shadow;
  border-radius: 5px;

  &__header,
  &__body,
  &__footer {
    padding: 16px;
  }

  &__header {
    background-color: #defcec;
    display: flex;
    justify-content: space-between;

    p,
    span {
      font-weight: bold;
      margin-bottom: 0;
    }
    span {
      color: #26a69b;
      font-size: 16px;
    }

    p {
      color: $primary;

      span {
        font-size: 14px;
      }
    }

    &--notification {
      svg {
        color: $gray;
      }
      div {
        display: flex;
        align-items: center;
        gap: 8px;

        svg {
          width: 20px;
          height: 20px;
          color: #00c45a;
        }
      }
    }
  }

  &__body {
    max-height: 360px;
    overflow-y: scroll;

    &__product {
      position: relative;

      .delete-icon {
        position: absolute;
        right: 0;
        top: 4px;
        color: $gray-4;
      }

      .col-3 {
        color: #aeaeae;
      }
    }
  }
}

.cart-notification {
  .ant-notification-notice-message {
    padding-right: 0 !important;
  }
}
