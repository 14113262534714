.email-confirmation {
  max-width: 560px;
  margin: 80px auto 0;
  text-align: center;

  h2 {
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    margin: 20px 0;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 16px;

    a {
      text-decoration: none;
      color: $primary;
      font-size: 14px;
      font-weight: 700;
    }
  }

  .email-confirmation__user-email {
    font-weight: 700;
  }

  .email-confirmation__actions {
    button {
      margin-top: 34px;
      margin-bottom: 20px;
      padding: 14px 64px;
      font-size: 14px;
      letter-spacing: 1.25px;
    }

    p {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: $primary;
      cursor: pointer;
    }
  }
}
