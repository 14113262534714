.marketplace-main {
  @include header-spacing;
  min-height: 1200px;

  &--cart {
    background-color: #f7f7f7;
  }
}

.marketplace-header {
  background: $primary-color;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 20px;

  &__disabled {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    background: transparent;

    .header-menu {
      margin: 0px 0px 0px 24px;

      li {
        letter-spacing: 1.1px;

        a {
          font-size: 14px !important;
        }
      }
    }
  }

  .header-mobile {
    display: none;
  }

  .header-logo {
    margin-right: 40px;

    img {
      height: 30px;
      width: auto;
    }
  }

  .header-right {
    margin-left: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > svg {
      margin-right: 20px;
      cursor: pointer;
    }

    a {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 1.25px;
      text-transform: uppercase;
      color: #ffffff;
      text-decoration: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }

      i,
      span {
        display: block;
      }

      span {
        font-size: 14px;
        line-height: 20px;
        text-transform: none;
        margin-left: 6px;
      }
    }

    .user-avatar {
      cursor: pointer;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      margin-right: 20px;

      @media screen and (max-width: 1023px) {
        span {
          display: none;
        }
        .anticon {
          display: none;
        }
      }

      .anticon {
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  .header-menu {
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      li {
        margin-right: 40px;

        &:last-child {
          margin-right: 0;
        }

        a {
          display: block;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 1.25px;
          text-transform: uppercase;
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
  }
}

.mobile-menu_wrapper {
  display: none;
}

@media screen and (max-width: 768px) {
  .marketplace-header {
    .header-menu {
      display: none;
    }
  }
}

@media screen and (max-width: 1023px) {
  .marketplace-header[data-open='1'] {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 0.3s ease-in-out;
  }

  .marketplace-header {
    padding: 0;
    padding-right: 16px;
    justify-content: space-between;
    transition: all 0.3s ease-in-out;

    &--ss {
      padding-right: 0 !important;

      &[data-open='1'] {
        background: #fff;

        .header-logo {
          opacity: 1;
        }

        button {
          background-color: #fff;

          svg {
            rect {
              fill: #fff;
            }

            path {
              fill: #aeaeae !important;
            }
          }
        }
      }

      .header-right {
        display: none;
      }

      .header-mobile {
        order: 2;

        button {
          background-color: transparent;

          svg {
            rect {
              fill: transparent;
            }

            path {
              fill: #fff;
            }
          }
        }
      }

      .header-logo {
        margin-left: 20px;
        opacity: 0.7;
      }
    }

    .mobile-none {
      display: none;
    }

    .header-mobile {
      display: flex;

      button {
        padding: 0;
        margin: 0;
        border: 0;
        box-shadow: none;
        background: transparent;
        line-height: 1;
      }
    }

    .header-right {
      a.mobile-none {
        display: none;
      }

      a {
        span {
          display: none;
        }
      }
    }
  }

  .mobile-menu_wrapper {
    display: block;
    position: fixed;
    left: 0;
    top: 64px;
    width: 100%;
    height: 0;
    opacity: 0;
    z-index: 99999999;
    background: #004d40;
    transition: all 0.3s ease-in-out;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      display: none;

      li {
        padding: 0 21px;

        a {
          display: block;
          padding: 16px 0;
          text-decoration: none;
          border-bottom: 1px solid rgba(0, 105, 93, 0.2);
          font-weight: 700;
          font-size: 18px;
          line-height: 28px;
          letter-spacing: 0.15px;
          color: #ffffff;
        }
      }
    }

    &--ss {
      &[data-open='1'] {
        padding: 100px 0px;
      }
      background: #fff !important;

      ul {
        li {
          a {
            color: #004d40;
            letter-spacing: 1.1px;
            text-transform: uppercase;
            border: none;
            font-size: 14px;
            line-height: 100%;
          }
        }
      }
    }
  }

  .mobile-menu_wrapper[data-open='1'] {
    height: calc(100vh - 64px);
    opacity: 1;

    ul {
      display: block;
    }
  }
}
