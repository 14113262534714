.logistic_quote {
  button {
    width: 280px;
    padding: 14px 24px;
    letter-spacing: 1.25px;
  }

  &__return-cart {
    display: flex;
    align-items: center;
    gap: 6px;
    color: #aeaeae;
    cursor: pointer;
    i {
      margin-top: 1px;
    }
    p {
      margin-bottom: 0;
    }
  }

  &__title {
    font-family: Open Sans;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin: 28px 0px;
  }

  &__seller,
  &__buyer {
    background-color: #fff;
    padding: 24px 20px;

    p {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 28px;
    }
    p:last-of-type {
      margin-bottom: 20px;
    }
  }

  &__seller {
    margin-bottom: 16px;
    &__address {
      .ant-radio-group {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }
  }

  &__buyer {
    margin-bottom: 28px;
  }

  &__shipment-data {
    > span {
      display: block;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      margin-bottom: 28px;
    }

    .col-lg-6:first-of-type {
      .ant-form-item {
        margin-bottom: 40px;
        &-label {
          margin-bottom: 16px !important;
        }
      }
    }
    .ant-form-item {
      margin-bottom: 28px;
      display: block;

      &-label {
        margin-bottom: 20px;

        label {
          color: #000;
        }
      }

      .ant-radio-group {
        display: flex;
        gap: 40px;
      }
    }

    &__pallets {
      .pallet-card {
        width: 200px;
        height: 88px;
        display: flex;
        gap: 16px;
        align-items: center;
        padding: 24px 20px;
        border: 1px solid #ededed;
        background-color: #fff;
        border-radius: 4px;
        cursor: pointer;

        &__description {
          p {
            margin: 0;
            font-size: 14px !important;
            font-weight: 700;
          }

          span {
            font-size: 14px;
            color: #4f4f4f;
          }
        }

        &--active {
          border: 3px solid $primary;
        }
      }
    }
  }

  &__review {
    .title {
      font-size: 16px;
    }
    .info {
      font-size: 14px;
      color: #4f4f4f;
    }
    &__addressess,
    &__shipment {
      background-color: #fff;
      width: 98%;
      padding: 20px;
      margin-bottom: 16px;
      border: 1px solid #ededed;
      border-radius: 4px;
      position: relative;
      .edit-link {
        position: absolute;
        right: 16px;
        top: 16px;
        i {
          cursor: pointer;
          color: #aeaeae;
        }
      }
    }
    &__addressess {
      &__seller,
      &__buyer {
        p {
          font-size: 14px;
        }
      }

      &__seller {
        padding-bottom: 20px;
        border-bottom: solid 1px #ededed;
      }

      &__buyer {
        margin-top: 20px;
      }
    }

    &__shipment {
      p,
      .col-2 {
        margin-top: 12px;
        font-size: 14px;
      }
      .row {
        border-bottom: solid 1px #ededed;
      }
      .col-2 {
        text-transform: capitalize;
        font-weight: 700;
      }
      .row:last-of-type {
        border-bottom: none;
      }
    }

    &__notes {
      textarea {
        background-color: #fff;
      }
    }

    &__submit {
      margin-bottom: 28px;
      .ant-select {
        width: 360px;
      }
    }
  }
}
