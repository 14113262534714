.productsWrapper {
  padding: 65px 0;

  .wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 28px;

    h2 {
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 700;
      font-size: 36px;
      line-height: 44px;
      color: #000000;
    }

    .link {
      margin-left: 24px;

      a {
        display: block;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 1px;
        text-decoration-line: underline;
        color: #004D40;
      }
    }
  }

  .viewAllMobile {
    margin-top: 28px;
    display: none;

    a {
      display: block;
      width: 100%;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 1.25px;
      text-transform: uppercase;
      color: #004D40;
      background: #FFFFFF;
      border: 2px solid #004D40;
      border-radius: 4px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .productsWrapper {
    padding: 30px 20px;

    .wrapper {
      margin-bottom: 28px;

      h2 {
        font-size: 28px;
        line-height: 32px;
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .productsWrapper {

    .wrapper {
      justify-content: space-between;

      h2 {
        font-size: 22px;
        line-height: 28px;
      }

      .link {
        display: none;
      }
    }

    .viewAllMobile {
      display: block;
      margin-top: 12px;
      margin-bottom: 28px;

      a {
        padding: 14px;
        text-decoration: none !important;
        outline: none;
        text-transform: uppercase;
        transition: all .3s ease-in-out;

        &:hover {
          color: #FFF;
          background: #004D40;
          transition: all .3s ease-in-out;
        }
      }
    }
  }
}
