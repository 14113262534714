.contacts {
  overflow-x: hidden;


  .item {

    a {
      text-decoration: none;
    }

    &:nth-child(1),
    &:nth-child(2) {
      margin-bottom: 20px;
    }
  }

  .map {
    margin-top: 20px;

    img {
      width: 100%;
      height: auto;
    }
  }
}

@media screen and (max-width: 800px) {
  .contacts {

    .item {
      margin-bottom: 20px;
    }

    .map {
      margin-top: 0;
    }
  }
}
