.products {
  flex-basis: calc(100% - 308px);
  width: calc(100% - 308px);
  margin-top: 40px;

  .products__list {
    margin-top: 24px;

    .item-supplier {
      .prices {
        span {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.1px;
          color: #000000;
        }
      }

      .supplier {
        flex-basis: calc(100% - 64px);
        padding-right: 8px;
        overflow: hidden;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.4px;
        white-space: nowrap;
        text-overflow: ellipsis;

        a {
          color: #00968a;
          text-decoration: none;
        }
      }

      .icons {
        ul {
          padding: 0;
          list-style-type: none;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin: -7px 0 0;

          li {
            margin-right: 8px;

            &:last-child {
              margin-right: 0;
            }

            img {
              height: 16px;
              width: 16px;
            }
          }
        }
      }
    }

    .product-item__list {
      padding: 12px;
      border: 1px solid #ededed;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      margin-bottom: 12px;
      transition: all 0.3s ease;

      .item-image {
        flex-basis: 165px;
        min-width: 165px;
        height: 231px;
        position: relative;
        overflow: hidden;
        margin-right: 28px;
        transition: all 0.3s ease;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          max-width: 100%;
          height: auto;
          transform: translate(-50%, -50%);
        }
      }

      .product-data {
        margin: 12px 0;
        flex-basis: calc(100% - 468px);
        padding-right: 24px;
        border-right: 1px solid #ededed;
        transition: all 0.3s ease;

        .product-name {
          margin-top: 12px;
          margin-bottom: 16px;
          font-style: normal;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.1px;
          font-weight: 600;
          color: #000000;
          transition: all 0.3s ease;

          a {
            color: #000000;
            outline: none;
            text-decoration: none;

            &:hover {
              color: #00968a;
            }
          }
        }

        .description {
          height: 70px;
          overflow: hidden;

          .ttw-code {
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.4px;
            color: #aeaeae;
          }

          span.rate-text {
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.4px;
            color: #aeaeae;
          }

          .ant-rate {
            margin-right: 8px;

            li {
              color: #ffd466;

              .anticon {
                svg {
                  height: 20px;
                  width: 20px;
                }
              }
            }
          }

          p,
          ul li,
          ol li {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: #808080;
            transition: all 0.3s ease;
          }
        }

        .tags {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-top: 24px;
          height: 20px;
          overflow: hidden;
          cursor: default;

          .tag {
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.4px;
            color: #009e40;
            margin-right: 8px;

            &:last-child {
              margin-right: 0;
            }
          }
        }

        .expand-product {
          margin-top: 40px;
          transition: all 0.3s ease;

          a {
            display: block;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 1px;
            text-decoration-line: underline;
            color: #004d40;
            transition: all 0.3s ease;

            svg {
              margin-left: 8px;
              margin-top: -2px;
              transition: all 0.3s ease;
            }
          }
        }

        .learn-more {
          margin-top: 8px;

          a {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.4px;
            color: #004d40;
            outline: none;
            text-decoration: none;
            transition: all 0.3s ease;
          }
        }
      }

      .product-prices {
        margin: 12px 12px 12px 0;
        padding-left: 24px;
        flex-basis: 255px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: all 0.3s ease;

        .prices {
          transition: all 0.3s ease;

          span {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.1px;
            color: #000000;
          }
        }

        .discount {
          background: #004d40;
          border-radius: 24px;
          padding: 4px 8px;
          font-weight: 700;
          font-size: 12px;
          line-height: 12px;
          color: #ffffff;
        }

        .min-order {
          margin-top: 8px;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.4px;
          color: #aeaeae;
          transition: all 0.3s ease;
        }

        .choose-options {
          transition: all 0.3s ease;

          a {
            display: block;
            outline: none;
            text-decoration: none;
            padding: 8px;
            width: 100%;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 20px;
            text-transform: uppercase;
            color: #004d40;
            border: 2px solid #004d40;
            border-radius: 4px;
            text-align: center;
            transition: all 0.2s ease;

            &:hover {
              background: #004d40;
              color: #ffffff;
            }
          }
        }
      }

      .expanded-details {
        height: 0;
        opacity: 0;
        visibility: hidden;
        border-top: 1px solid transparent;
        transition: all 0.3s ease;
        max-width: 100%;

        .product-attributes {
          margin-top: 40px;

          .product-attribute {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding: 8px 0;
            border-bottom: 1px solid #ededed;

            .attr_name {
              width: 150px;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              display: flex;
              align-items: center;
              letter-spacing: 0.1px;
              color: #aeaeae;
            }

            .attr-options {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;

              .option {
                margin-right: 12px;

                &:last-child {
                  margin-right: 0;
                }

                a {
                  margin-top: 8px;
                  margin-bottom: 8px;
                  border-radius: 4px;
                  background: #f7f7f7;
                  border: 1px solid #ededed;
                  display: block;
                  padding: 2px 8px;
                  text-decoration: none !important;
                  outline: none;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 20px;
                  letter-spacing: 0.1px;
                  color: #808080;
                }
              }

              .active a {
                background: #defcec;
                border: 2px solid #00968a;
                color: #000;
              }
            }
          }
        }

        .description-card {
          padding: 20px;
          background: #ffffff;
          border: 1px solid #ededed;
          margin-bottom: 12px;
          transition: all 0.2s ease;

          &:last-child {
            margin-bottom: 0;
          }

          .card-title {
            margin-top: 0;
            margin-bottom: 21px;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #000000;
            transition: all 0.2s ease;
          }

          p,
          ul li,
          ol li {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: center;
            letter-spacing: 0.1px;
            color: #000000;
            transition: all 0.2s ease;
          }

          table {
            border: 0;
            transition: all 0.2s ease;

            tr {
              border: 0;
              transition: all 0.2s ease;

              &:nth-child(2n) {
                background: #f7f7f7;
              }

              td {
                border: 0;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.1px;
                color: #000000;
                transition: all 0.2s ease;
              }
            }
          }

          .tags {
            transition: all 0.2s ease;

            ul {
              padding: 0;
              margin: 0;
              list-style-type: none;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              transition: all 0.2s ease;

              li {
                padding: 6px 12px;
                background: #e0f2f1;
                border-radius: 4px;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 0;
                letter-spacing: 0.1px;
                margin-right: 8px;
                color: #004d40;
                transition: all 0.2s ease;

                &:last-child {
                  margin-right: 0;
                }

                &:first-child {
                  margin-right: 16px;
                  padding: 0;
                  background: transparent;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 20px;
                  letter-spacing: 0.1px;
                  color: #aeaeae;
                }
              }
            }
          }
        }
      }

      .expanded-details__visible {
        height: auto;
        opacity: 1;
        visibility: visible;
        margin-top: 20px;
        border-top: 1px solid #ededed;
        transition: all 0.3s ease;
        max-width: 100%;
      }
    }

    .product-item {
      padding: 16px;
      border: 1px solid #ededed;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      margin-bottom: 24px;
      transition: all 0.3s ease;

      .grid-prices {
        .prices {
          span {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.1px;
            color: #000000;
          }
        }

        .discount {
          background: #004d40;
          border-radius: 24px;
          padding: 4px 8px;
          font-weight: 700;
          font-size: 12px;
          line-height: 12px;
          color: #ffffff;
        }
      }

      .item-image {
        height: 272px;
        width: 100%;
        position: relative;
        overflow: hidden;
        margin-bottom: 8px;
        transition: all 0.3s ease;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          height: 100%;
          max-height: 272px;
          width: auto;
          transform: translate(-50%, -50%);
        }
      }

      .product-name {
        margin-top: 4px;
        margin-bottom: 4px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        height: 40px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        // white-space: nowrap;
        transition: all 0.3s ease;

        a {
          color: #000000;
          outline: none;
          text-decoration: none;

          &:hover {
            color: #00968a;
          }
        }
      }

      .min-order {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.4px;
        color: #aeaeae;
        transition: all 0.3s ease;
      }

      .tags {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 16px;
        height: 20px;
        overflow: hidden;
        cursor: default;

        .tag {
          font-weight: 400;
          font-size: 11px;
          line-height: 20px;
          letter-spacing: 0.4px;
          color: #009e40;
          margin-right: 8px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    margin: 0px;

    h1 {
      font-size: 24px;
      margin-bottom: 24px;
    }

    .grid-list {
      margin-top: 8px;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1339px) {
  .products .products__list .product-item .item-image {
    height: 169px;
  }
}

@media (min-width: 1340px) and (max-width: 1700px) {
  .products .products__list .product-item .item-image {
    height: 219px;
  }
}
