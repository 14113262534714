.verification-layout {
  position: relative;
  height: calc(100vh - 81px);
  z-index: 1;

  h1 {
    margin-top: 0;
    margin-bottom: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
    color: #000000;
  }

  h4 {
    margin-top: 0;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #000000;
  }

  .layout-content {
    position: relative;
    min-height: 100%;
    z-index: 2;

    .main-content {
      width: calc(100% - 450px);
      padding-bottom: 100px;

      .content-container {
        display: block;
        margin: 0 auto;
        width: 100%;
        max-width: 840px;
        padding: 0 15px;
      }
    }

    .verification-nav {
      position: fixed;
      right: 0;
      top: 0;
      width: 420px;
      height: 100%;
      z-index: 3;
      background: #F7F7F7;

      .nav-content {
        padding: 0 30px;
        margin-top: 100px;
        user-select: none;
        cursor: default;

        .nav-progress {
          margin-bottom: 40px;

          .ant-progress-circle-trail {
            stroke: #B2DFDB;
          }

          .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
            stroke: $primary-color;
          }

          .ant-progress-circle .ant-progress-text {
            color: $primary-color;
            font-weight: bold;
            font-size: 28px;
            line-height: 1;
          }

          .progress__description {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: #000000;
            margin-left: 20px !important;
          }
        }

        .nav-steps {

          .steps__list {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
              display: flex;
              flex-wrap: nowrap;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              padding-bottom: 20px;
              position: relative;

              &:after {
                content: "";
                position: absolute;
                top: 34px;
                left: 15px;
                height: 20px;
                width: 4px;
                background: #EDEDED;
              }

              &:last-child {
                padding-bottom: 0;

                &:after {
                  display: none;
                }
              }

              .step__number {
                margin-right: 12px;
                width: 34px;
                line-height: 28px;
                border: 3px solid #ededed;
                text-align: center;
                border-radius: 28px;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                letter-spacing: 0.1px;
                color: #808080;
                background: #EDEDED;
              }

              .step__name {

                a {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 20px;
                  letter-spacing: 0.25px;
                  color: #000000;
                  text-decoration: none;

                  &:hover {
                    color: $primary-color;
                  }
                }
              }
            }

            .active {

              .step__number {
                border-color: $primary-color;
              }
            }
          }
        }
      }
    }
  }

  .verification-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #FFF;
    box-shadow: 8px 0px 20px rgba(0, 0, 0, 0.12);
    padding: 18px 0;
    z-index: 5;

    .ant-btn {
      height: 44px;
    }

    a {
      color: $primary-color;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      text-decoration: none;
      letter-spacing: 0.25px;
    }
  }

  .company-units {
    background: #F7F7F7;
    padding: 20px;
    border-radius: 4px;
  }

  .add-unit {
    margin-bottom: 0;

    button {
      border: none !important;
      padding: 0 !important;
      margin: 0 !important;
      outline: none !important;
      box-shadow: none !important;
      text-align: left;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #004D40;
    }
  }

  .company-logo {

    .image-preview {

      img {
        max-width: 100%;
        height: auto;
      }

      p {
        margin-bottom: 0;
        margin-top: 20px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #808080;
      }
    }

    .logo__upload {
      width: 100%;
      height: 340px;
      border: 3px dashed #CCCCCC;
      box-sizing: border-box;
      border-radius: 5px;
      position: relative;

      input[type="file"] {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
        cursor: pointer;
      }

      p {
        position: absolute;
        width: 100%;
        max-width: 170px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #808080;

        span {
          font-weight: bold;
          color: #00968A;
        }
      }
    }
  }

  .brands-description {
    margin-bottom: 50px;

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #808080;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      strong {
        font-weight: bold;
        color: #000;
      }
    }
  }

  .brand-item {
    background: #F7F7F7;
    border-radius: 4px;
    margin-bottom: 30px;
    padding: 20px;

    .image-circle {
      width: 60px;
      height: 60px;
      border-radius: 60px;
      overflow: hidden;

      img {
        max-width: 100%;
        height: auto;
      }
    }


    .brand-logo-upload {
      margin-bottom: 20px;
    }

    .brand-logo-name {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #000000;
    }

    .upload-link {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #004D40;
      position: relative;
      cursor: pointer;

      input {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
        cursor: pointer;
      }
    }
  }

  .company__industries {
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    align-items: center;
    width: calc(100% + 40px);
    margin-left: -10px;

    .industry-item {
      width: 151px;
      height: 144px;
      margin: 4px;
      user-select: none;
      cursor: pointer;

      .industry__wrapper {
        width: 151px;
        height: 144px;
        border: 1px solid #EDEDED;
        border-radius: 4px;
        position: relative;
        padding: 12px 4px;
        text-align: center;
      }

      .ant-checkbox-wrapper {

        .ant-checkbox {
          opacity: 0;
          visibility: hidden;
          display: none;
        }

        .ant-checkbox-checked + span {

          .industry__wrapper {
            border: 3px solid $primary-color;
          }
        }
      }

      .industry__icon {

        img {
          height: 60px;
          width: auto;
        }
      }

      .industry__name {
        margin-top: 12px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.1px;
        color: #000000;
      }
    }
  }

  .sub-industries__wrapper {

    .sub-industries {

      .sub-industry__check {
        margin-bottom: 10px;
      }
    }

    .industry__item {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #EDEDED;

      &:last-child {
        border-bottom: 0;
      }

      .industry__label {

        img {
          width: 60px;
          height: 60px;
        }

        .industry__name {
          margin-top: 12px;
          margin-bottom: 0;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.1px;
          color: #000000;
        }
      }
    }
  }

  .additional-info-gallery {

    .gallery__wrapper {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .gallery__item {
        width: 140px;
        height: 140px;
        overflow: hidden;
        border-radius: 4px;
        border: 1px solid #EDEDED;
        margin-right: 20px;
        margin-bottom: 20px;
        position: relative;

        .gallery-item__remove {
          position: absolute;
          top: 5px;
          right: 5px;
          font-size: 16px;
          color: $danger-color;
          cursor: pointer;
        }

        img {
          height: 140px;
          width: auto;
        }
      }

      .gallery__upload {
        position: relative;
        z-index: 1;
        cursor: pointer;

        input {
          opacity: 0;
          z-index: 3;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
        }

        p {
          position: absolute;
          z-index: 2;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.1px;
          color: $primary-color;
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  .upload-docs {
    margin-top: 20px;
    padding: 20px;
    background: #F7F7F7;
    border-radius: 4px;

    h2 {
      margin-top: 0;
      margin-bottom: 20px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #000000;
    }

    .docs__uploader {

      .uploader__radio {
        margin-bottom: 20px;
      }

      .uploader__btn {
        margin-top: 20px;
        border-radius: 4px;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: #FFFFFF;
        background: $primary-color;
        border-color: $primary-color;
      }

      .uploader__btn:disabled {
        background: #CCCCCC;
        border-color: #CCCCCC;
        color: #FFF;
      }

      .uploader__upload {

        .upload__placeholder {
          position: relative;
          z-index: 1;
          margin: 8px 0;

          input {
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 5;
            cursor: pointer !important;
          }

          span {
            position: relative;
            z-index: 2;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.1px;
            color: #808080;
            cursor: pointer;

            strong {
              z-index: 2;
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.1px;
              color: #004D40;
              padding-right: 8px;
              cursor: pointer;
            }
          }

          .uploaded {

            .anticon {
              padding-left: 8px;
              font-size: 16px;
            }
          }
        }
      }
    }

    .docs__list {
      margin-top: 20px;

      .doc__item {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 8px 16px;
        background: #FFF;
        border-radius: 4px;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }

        .doc-icon {
          width: 32px;
          height: 32px;
          background: #80CBC5;
          border-radius: 32px;
          position: relative;
          margin-right: 12px;

          .anticon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 16px;
            color: #FFF;
          }
        }

        .doc-name {
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.25px;
          color: #000000;
          margin-right: auto;
        }

        .doc-remove {
          font-size: 18px;

          &:hover {
            color: $danger-color;
          }
        }
      }
    }
  }
}

.success-screen__wrapper {
  height: calc(100vh - 120px);
  position: relative;

  .success-screen {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 600px;
    text-align: center;

    .screen__logo {
      margin-bottom: 30px;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    h1 {
      margin-top: 0;
      margin-bottom: 20px;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 44px;
      text-align: center;
      color: #000000;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.1px;
      color: #000000;
    }
  }
}
