.products-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @import "filters";
  @import "nav";
  @import "products";

  @import "sellers-brands";

  &__white {
    .products .products__list .product-item,
    .products .products__list .product-item__list {
      background: #FFF;
    }
  }

  @media screen and (max-width: $sm-max-width) {

    .col-6 {
      padding: 4px;
    }
  }
}

.products-list__no-filters {
  .filters {
    display: none;
  }

  .products {
    width: 100%;
    flex: 0 0 100%;

    .explore-nav {
      display: none;
    }
  }
}

.products-list .products .products__list .product-item,
.products-list .products .products__list .product-item__list {
  transition: all .3s ease;

  &:hover {
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.12);
    transition: all .3s ease;
  }
}

@media screen and (max-width: 720px) {
  .products-list {

    .products {
      flex-basis: 100%;
      width: 100%;
      // margin-top: 40px;
    }

    .filters {
      display: none;
    }

    .explore-header .grid-list ul {
      display: none;
    }
  }
}

@media screen and (max-width: 740px) {
  .products-list .products .products__list .product-item .item-image {
    height: 200px;
  }

  .products-list .products .products__list .product-item .item-supplier .icons {
    display: none;
  }

  .products-list .products .products__list .product-item .product-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }

  .products-list .products .products__list .product-item .grid-prices {
    margin-top: 8px;
  }

  .products-list .products .products__list .product-item .grid-prices .d-flex {
    flex-direction: column !important;
    align-items: flex-start !important;

    .prices {
      order: 2;
      margin-top: 8px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
    }
  }

  .products-list .products .products__list .product-item .min-order,
  .products-list .products .products__list .product-item .tags {
    display: none;
  }
}
