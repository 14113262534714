@import '../../../scss/common/mixins';

$tag-list__item-bg: #defcec;
$tag-list__item-color: #004d40;

.tag-list__item {
  width: fit-content;
  padding: 0.2rem 0.5rem;
  border-radius: 0.25rem;
  @include flex(row, center, center);
  gap: 0.5rem;
  background-color: $tag-list__item-bg;
  color: $tag-list__item-color;
  text-transform: capitalize;
}
