.hero {
  margin-top: -18px;
  background-image: url('https://d17jozkkv5mv8m.cloudfront.net/web/marketplace/marketplace-home-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 75%;

  &__wrapper {
    padding: 60px 0 180px 0;

    @media screen and (min-width: 720px) and (max-width: 1023px) {
      padding: 150px 0 200px 40px;

      &__content {
        margin: 0px 0px 60px 0px !important;
      }
    }

    @media screen and (min-width: 1024px) {
      padding: 170px 0 200px 80px;
    }

    &__content {
      margin: 0px 0px 24px 0px;
      padding: 0;
      text-align: left;

      h1 {
        margin: 0 0 12px 0;
        font-weight: 700;
        font-size: 48px;
        line-height: 68px;
        color: $white;

        span {
          color: $secondary-color;

          @media screen and (max-width: '719px') {
            &::after {
              content: '\A';
              white-space: pre;
            }
          }
        }
      }

      h5 {
        margin: 0;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.25px;
        color: $white;

        @media screen and (min-width: '720px') {
          max-width: 540px;
          margin: 0px 0px 36px 0px;
          font-size: 24px;
          line-height: 36px;
        }
      }
    }
  }
}

@media screen and (max-width: 719px) {
  .hero {
    &__wrapper {

      &__content {
        h1 {
          font-size: 36px;
          line-height: 49px;
        }

        h5 {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}


