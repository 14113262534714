.content {
  background: #f7f7f7;

  @media screen and (min-width: 720px) {
    padding: 32px 0px;
  }

  img {
    width: 100%;
    height: auto;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: #000000;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #4F4F4F;
    margin-bottom: 12px;
  }

  a {
    display: block;
    outline: none;
    text-decoration: none;
    background: #004D40;
    border-radius: 4px;
    padding: 14px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #FFFFFF;
    transition: all .3s ease;

    svg {
      margin-top: -4px;
      margin-right: 10px;
    }

    &:hover {
      color: #FFFFFF;
      background: lighten(#004D40, 2%);
      transition: all .3s ease;
    }
  }

}
