@mixin wrapperPadding {
  padding: 16px 20px;
}

.cart {
  padding-bottom: 128px;
  &__products {
    h3 {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0px;
      margin-bottom: 28px;
    }
    &__header {
      display: flex;
      justify-content: space-between;
      &__delete-controller {
        cursor: pointer;
        color: #aeaeae;
        i {
          margin-right: 12px;
        }
      }
    }

    &__wrapper {
      margin-top: 20px;
      margin-bottom: 28px;
      background-color: #fff;

      &__header {
        border-bottom: solid 1px #ededed;
        @include wrapperPadding();

        .brand-logo {
          overflow: hidden;
          display: flex;
          align-items: center;

          img {
            border-radius: 50px;
            border: 1px solid #ededed;
            height: 50px;
            width: 50px;
            margin-left: 16px;
            margin-right: 12px;
          }

          p {
            margin-bottom: 0px;
          }
        }

        &__seller {
          gap: 8px;
          cursor: pointer;
          color: $primary-color;

          i {
            color: $primary-color;
            font-size: 18px;
          }
        }

        & > div {
          display: flex;
          align-items: center;
        }
      }

      &__list {
        &__item {
          @include wrapperPadding();
          border-bottom: solid 1px #ededed;
          &__preview {
            display: flex;
            align-items: center;
            gap: 16px;
            img {
              max-width: 68px;
              width: 100%;
            }
          }

          &__info {
            a {
              font-weight: 700;
              color: $black;
              line-height: 20px;
              font-size: 14px;
              text-decoration: none;
            }

            i {
              font-size: 20px;
              color: #cccc;
              cursor: pointer;
            }

            p {
              color: #aeaeae;

              span {
                color: $black;
              }
            }

            &__packaging {
              display: flex;
              align-items: center;

              i {
                cursor: pointer;
                margin-left: 4px;
              }
            }

            .row {
              .qty__wrapper {
                max-width: 70%;
                margin: 0 auto;
                text-align: center;
              }

              .col-lg-4:last-child {
                text-align: right;
                p {
                  color: $black;
                  margin-bottom: 0px;
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 24px;
                }

                p:last-child {
                  font-weight: 400;
                  font-size: 12px;
                  color: #aeaeae;
                }
              }
            }
          }
        }

        &__item:last-of-type {
          border-bottom: none;
        }
      }

      &__footer {
        @include wrapperPadding();

        &__ship-total {
          background-color: #f7f7f7;
          border-radius: 4px;
          padding: 16px;
          margin-bottom: 20px;
          p {
            margin-bottom: 0;
          }
          .col-lg-3 {
            color: #aeaeae;
          }
        }

        &__fees {
          button {
            margin-right: 20px;
            height: 36px;
            i {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  &__summary {
    border-radius: 5px;
    padding: 0;
    height: fit-content;
    background-color: #fff;

    &__header {
      padding: 16px 20px;
      background-color: #defcec;
      width: 100%;

      p {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 0px;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;

      img {
        max-width: 100px;
      }

      & > img {
        margin-top: 20px;
      }

      .ant-collapse-item {
        background-color: #fff;
        .ant-collapse-header {
          .brand-logo {
            display: flex;
            align-items: center;
            gap: 12px;

            p {
              margin-bottom: 0;
            }

            img {
              border-radius: 50%;
              max-width: 36px;
            }

            span {
              margin-left: auto;
              margin-right: 32px;
            }
          }

          .ant-collapse-arrow {
            transform: scale(2.5);
            top: 0;
          }
        }
      }

      &__collapse-body {
        &__item {
          padding: 16px 12px;
          background-color: #f7f7f7;
          border-radius: 4px;

          &__info {
            display: flex;
            gap: 12px;
            align-items: center;
            margin-bottom: 12px;
            p {
              font-size: 14px;
              line-height: 20px;
              color: #808080;
              margin-bottom: 0;
            }
            img {
              max-width: 20px;
              margin-right: 8px;
            }
          }
        }

        &__product {

          .col-3 {
            color: #aeaeae;
          }
        }
      }
    }

    &__footer {
      padding: 20px;

      &__t-price {
        display: flex;
        justify-content: space-between;
        p {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0px;
        }

        span {
          font-size: 18px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0px;
          color: #26a69b;
        }
      }

      &__t-discount {
        display: flex;
        justify-content: space-between;
        p {
          font-size: 14px;
          line-height: 20px;
          color: #808080;
          margin-bottom: 0;
        }
      }

      button {
        width: 100%;
        margin-top: 16px;
      }
    }
  }
}

/////// Packaging pop-over

.cart__products__wrapper__list__item__info__packaging__item {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 12px;

  p {
    width: fit-content;
    font-size: 14px;
    line-height: 20px;
    color: #808080;
    margin-bottom: 0;
  }

  img {
    max-width: 20px;
    margin-right: 8px;
  }
}
////// END Packaging pop-over
