.product-upload__packages {

  .package-type {
    border: 1px solid #EDEDED;
    box-sizing: border-box;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    min-height: 450px;
    z-index: 1;

    .ant-checkbox {
      position: absolute;
      left: 16px;
      top: 19px;
      z-index: 5;
    }

    .ant-checkbox + span {
      padding: 0;
    }

    .type__head {
      background: #E0F2F1;
      border-radius: 3px 3px 0px 0px;
      height: 56px;
      line-height: 56px;
      position: relative;
      z-index: 2;

      .head__title {
        position: absolute;
        left: 48px;
        top: 50%;
        transform: translateY(-50%);
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #004D40;
        padding-right: 16px;
      }
    }

    .type__icon {
      padding: 16px 0;
      text-align: center;
    }

    .type__description {
      padding: 0 16px 16px;

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
      }
    }
  }
}
