.companyLogo {
  width: 120px;
  height: 120px;
  display: block;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  border: 1px solid #D8D8D8;
  border-radius: 80px;

  img {
    max-width: 80%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.companyName {
  text-align: center;
  margin-top: 24px;
  margin-bottom: 62px;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  color: #004D40;
}

.companyDetails {
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    height: 26px;
    width: 26px;
    margin-top: -8px;
  }

  p {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #000000;
    margin-bottom: 16px;
  }

  h4 {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: .15px;
    color: #004D40;
  }

  .industries {

    .industry {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .icon {
        margin-bottom: 16px;
        width: 60px;
        height: 60px;
        position: relative;
        background: #DEFCEC;
        border-radius: 30px;

        img {
          height: 40px;
          width: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .name {
        margin-top: 0;
        margin-bottom: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: .1px;
        color: #000000;
        min-height: 40px;
      }
    }
  }
}
