.view-controller {
  display: flex;
  gap: 24px;

  &__icon {
    cursor: pointer;
    img {
      filter: grayscale(100%);
      transition: filter 0.3s ease-in-out;
    }
    img.active {
      filter: grayscale(0%);
    }
  }
}
