.qty {
  .qty__wrapper {
    .wrap {
      input {
        padding: 0;
        height: 36px;
        border-radius: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        border: 1px solid #ededed !important;
        border-left: 0 !important;
        border-right: 0 !important;
        letter-spacing: 0.1px;
        color: #000000;
        padding-left: 10px;
        padding-right: 10px;
      }

      button {
        border: 1px solid #ededed;
        box-sizing: border-box;
        border-radius: 4px 0 0 4px;
        color: #808080;
        height: 36px;
        padding-left: 4px;
        padding-right: 4px;
        box-shadow: none !important;
        outline: none !important;

        svg {
          height: 16px;
          width: 16px;
        }

        .anticon {
          vertical-align: 1px;
        }
      }

      &:last-child {
        button {
          border-radius: 0px 4px 4px 0px;
        }
      }
    }
  }

  .qty-info {
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 0.5;
      letter-spacing: 0.4px;
      color: #808080;
      // margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
        margin-top: 1rem;
      }
    }

    &--cart {
      p {
        color: #26a69b;
      }
    }
  }
}
