@import './hero';
@import './promotion-banner';
@import './carousel';

.homepage-container {
  .role-selection-card {
    margin-bottom: 20px;
    height: 220px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: $white;

    img {
      width: 100% !important;
      height: 100% !important;
    }

    p {
      display: none;
    }

    @media screen and (min-width: 1023px) {
      // padding: 12px !important;

      h3 {
        font-size: 0.9rem;
      }
    }
  }
}

@media screen and (max-width: 719px) {
  .carousel__control {
    display: none;
  }

  .view-all-link {
    display: none;
  }

  .products__list {
    &--carousel {
      display: none !important;
    }
  }
}

@media screen and (min-width: 720px) {
  .products__list {
    &--default {
      display: none !important;
    }
  }
}
