.navigation-tree {
  &__return {
    max-width: fit-content;
    margin-bottom: 16px;
    color: #808080;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    cursor: pointer;
    transition: color 0.3s ease-in-out;

    &__arrow {
      margin-top: 1px;
    }

    &:hover {
      color: $primary;
    }
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 16px;
    text-transform: capitalize;
  }

  &__list {
    list-style: none;

    &__items {
      padding: 0;
      list-style: none;
      font-size: 14px;

      &__item {
        max-width: fit-content;
        margin-bottom: 8px;
        cursor: pointer;
        transition: color 0.2s ease-in-out;

        &--active {
          color: #26a69b;
        }

        &:hover {
          color: #26a69b;
        }
      }
    }

    &__radio-group {
      margin-bottom: 12px;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  &__show-more {
    max-width: fit-content;
    display: flex;
    align-items: center;
    gap: 8px;
    color: $primary;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }
}
