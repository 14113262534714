.signup {

  &__return {
    display: flex;
    align-items: center;
    width: fit-content;
    color: $primary;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    cursor: pointer;

    i {
      margin-top: 2px;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .signup__login-disclaimer {
    text-align: center;
    font-size: 16px;
    padding-bottom: 32px;

    a {
      color: $primary;
      font-weight: 700;
      text-decoration: none;
    }
  }
}
