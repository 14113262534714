.carousel__control {
  display: flex;
  gap: 16px;

  button {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    background-color: $white;
    border: 1px solid #ededed;

    svg {
      width: 8px;
      stroke-width: 1px;
    }
  }
}
