.ant-select-dropdown {
  z-index: 100000;
}

.modal-customs-fees {
  &__body {
    &__header {
      h5 {
        font-size: 18px;
        font-weight: 700;
      }
      p {
        margin: 20px 0px 24px;
        font-size: 14px;
      }
    }
    &__form {
      padding-bottom: 24px;
      &__select-opt, .ant-select-selection-item {
        img {
          margin-right: 8px;
          border-radius: 2px;
          width: 24px;
          height: 16px;
        }
        span {
          font-size: 14px;
        }
      }
    }
  }

  .ant-modal-footer {
    border: none;
  }
}
