.shopByWrapper {
  margin: 40px 0;

  h2 {
    margin-top: 0;
    margin-bottom: 40px;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: #000000;
  }

  a {
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    text-decoration-line: underline;
    color: #004d40;
  }

  .viewAllMobile {
    margin-top: 28px;
    display: none;

    a {
      display: block;
      width: 100%;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 1.25px;
      text-transform: uppercase;
      color: #004d40;
      background: #ffffff;
      border: 2px solid #004d40;
      border-radius: 4px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .shopByWrapper {
    margin: 30px 20px;

    h2 {
      font-size: 28px;
      line-height: 32px;
    }
  }
}

@media screen and (max-width: 520px) {
  .shopByWrapper {
    margin: 20px 0;
    padding: 0 2px;

    .viewAllMobile {
      display: block;
      margin-top: 12px;
      margin-bottom: 28px;

      a {
        padding: 14px;
        text-decoration: none !important;
        outline: none;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: #fff;
          background: #004d40;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
}
