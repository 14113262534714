.ant-pagination-prev:hover button,
.ant-pagination-next:hover button {
  border-color: $primary-color;
}

.ant-pagination-prev,
.ant-pagination-next {
  border: 1px solid #ededed;
  border-radius: 4px;

  svg {
    margin-top: -3px !important;
    width: 10px !important;
    height: 10px !important;
  }
}

.ant-pagination-disabled {
  background-color: #ededed;
}

.anticon-double-right {
  color: $primary-color !important;
}

.ant-pagination-jump-next {
  border: 1px solid #ededed !important;
  border-radius: 4px;

  .ant-pagination-item-ellipsis {
    color: $black !important;
    letter-spacing: 0.5px !important;
    font-size: 10px !important;
    font-weight: 500 !important;
    margin-top: 3px !important;
  }
}

.ant-pagination-item {
  margin: 0px 4px !important;
  padding: 0px 2px !important;

  &:hover {
    border-color: $primary-color !important;
  }

  &:not(.ant-pagination-item-active) {
    font-weight: 600 !important;
    border: 1px solid #ededed !important;
    border-radius: 4px;
    &:hover {
      color: $primary-color !important;
    }
  }

  a {
    padding: 0px !important;
  }
}

.ant-pagination-item-active {
  font-weight: 700;
  background: #fff;
  border-color: $primary-color;
}

.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: $primary;
}
