.role-selection {
  text-align: center;
  margin-top: 80px;

  .role-selection__header {
    margin-bottom: 24px;

    h2 {
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 44px;
    }

    p {
      margin-top: 16px;
      font-size: 16px;
    }
  }

  .row {
    margin: 0 220px;
  }

  button {
    max-width: 380px;
    letter-spacing: 1.25px;
    margin: 24px 0;
  }

  p {
    font-size: 16px;

    a {
      color: $primary;
      font-weight: 700;
      text-decoration: none;
    }
  }
}
