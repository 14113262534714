.industries {
  .industryBlock {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 14px;
    background: #e0f2f1;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0);
    margin-bottom: 24px;
    border-radius: 5px;
    text-decoration: none;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;
    }

    .icon {
      width: 48px;
      margin-right: 8px;

      img {
        height: 48px;
        width: auto;
      }
    }

    .name {
      width: calc(100% - 48px - 16px);
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      min-height: 60px;
      display: flex;
      align-items: center;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.countries {
  .industryBlock {
    background: #f7f7f7;

    .icon {
      margin-right: 16px;
      border-radius: 30px;
      position: relative;
      background: #ffffff;
    }

    .name {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #333333;
    }
  }
}

.cilurarModel {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 0;

  h3 {
    margin-top: 0;
    margin-bottom: 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: #000000;
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.25px;
    color: #000000;
    margin-bottom: 24px;

    a {
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 1px;
      text-decoration-line: underline;
      color: #004d40;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.news {
  @media screen and (min-width: 720px) {
    iframe {
      height: 400px;
    }

    .newsItem {
      flex-wrap: nowrap !important;
      gap: 16px !important;
      align-items: center !important;

      .logo {
        img {
          max-width: 180px !important;
          width: 180px !important;
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .newsItem {
      align-items: flex-start !important;
      margin-bottom: 12px;

      .logo {
        img {
          max-width: 100px !important;
          width: 100px !important;
          height: 100px !important;
          object-fit: fill !important;
        }
      }
    }
  }

  iframe {
    width: 100%;
  }

  .newsItem {
    display: flex;
    flex-wrap: wrap;

    .logo {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      gap: 16px;

      img {
        width: 100%;
        // max-width: 100%;
        max-width: 79px;
        height: auto;
      }
    }

    h3 {
      margin-top: 0;
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #000000;

      a {
        text-decoration-line: underline;
        text-transform: capitalize;
        color: #004d40;
      }
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      // letter-spacing: 0.1px;
      color: #808080;
      margin-bottom: 16px;

      a {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 1px;
        text-decoration-line: underline;
        text-transform: capitalize;
        color: #004d40;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.features {
  padding: 40px 0;

  .feature {
    padding: 0;

    img {
      height: 180px;
      width: auto;
    }

    h3 {
      margin-top: 28px;
      margin-bottom: 0;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      letter-spacing: 0.15px;
      color: #004d40;
    }
  }
}

@media screen and (max-width: 720px) {
  .industries {
    .industryBlock {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      text-align: center;
      min-height: 140px;

      .icon {
        margin-right: 0;
      }

      .name {
        justify-content: center;
      }
    }
  }

  .countries {
    .industryBlock {
      text-align: center;

      .icon {
        margin: 0 auto;
      }

      .name {
        justify-content: center;
      }
    }
  }

  .features {
    .feature {
      text-align: center;

      img {
        max-width: 100%;
        height: auto;
      }

      h3 {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1900px) {
  .industries {
    .industryBlock {
      min-height: 92px;
      overflow: hidden;
    }
  }
}
